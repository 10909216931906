import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import _ from 'lodash'

import RaisedButton from 'material-ui/RaisedButton'
import Layout from 'components/Layout'
import Form from 'components/Form'
import ConnectAs from 'components/ConnectAs'

class BooksDetail extends Component {
  constructor(props) {
    super(props)

    this.dropStyle = {
      border: '1px solid #cacaca',
      background: '#efefef',
      marginBottom: '50px',
      textAlign: 'center',
      padding: '80px 10px',
      fontSize: '30px',
      cursor: 'pointer'
    }
  }
  render() {
    const { container, form, data, onChange, onSubmit } = this.props
    return (
      <Layout {...this.props} title="Book">
        <div className="books-detail-page detail-page">
        <div className="dropzone">
            <Dropzone onDrop={container.onDrop} style={this.dropStyle}>
              {container.uploadStatus === 2 && <p>Uploader une photo (jpg, png etc...)</p>}
              {container.uploadStatus === -1 && (
                <p>
                  Erreur : {container.uploadErrorMessage} - {container.uploadErrorCode}
                </p>
              )}
              {container.uploadStatus === 0 && <p>Upload en cours, patientez</p>}
              {container.uploadStatus === 1 && <p>Upload réussi</p>}
            </Dropzone>
          </div>
          {_.get(data, 'cover') ? (
            <img
              className="book-cover"
              src={process.env.REACT_APP_SERVER + _.get(data, 'cover.path')}
            />
          ) : (
            ''
          )}
          <Form schema={form} data={data} onChange={onChange} onSubmit={onSubmit} />
        </div>
      </Layout>
    )
  }
}

export default BooksDetail
