import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import Layout from 'components/Layout'
import StatusList from './partials/statusList'
import Chat from './partials/chat'
import RaisedButton from 'material-ui/RaisedButton'

import './style.css'

class MatchsDetail extends Component {
  render() {
    const { match, text, setChatRef, onChangeText, sendMessage, cancel, confirm } = this.props
    return (
      <Layout {...this.props} title="Match detail">
        {match ? (
          <div className="matchs-detail-page detail-page">
            <div className="createdAt align-center">
              <strong>Créé le : </strong>
              {moment(match.createdAt).format('DD/MM/YYYY HH:mm')}
            </div>
            <div className="status align-center">
              <strong>Status : </strong>
              {match.status}
            </div>
            <div className="promoCode">
              <strong>Promo code : </strong>
              {match.promoCode}
            </div>
            <div className="users columns">
              <div className="column-2 column-left">
                <h3>Organisateur</h3>
                {_.get(match, 'organizer.picture.path') && (
                  <img
                    className="picture"
                    src={process.env.REACT_APP_SERVER + _.get(match, 'organizer.picture.path')}
                  />
                )}
                <div>
                  {match.organizer.firstname} {match.organizer.lastname}
                </div>
                <div>{match.organizer.email}</div>
              </div>
              <div className="column-2 column-right">
                <h3>Speaker</h3>
                {_.get(match, 'speaker.picture.path') && (
                  <img className="picture" src={process.env.REACT_APP_SERVER + _.get(match, 'speaker.picture.path')} />
                )}
                <div>
                  {match.speaker.firstname} {match.speaker.lastname}
                </div>
                <div>{match.speaker.email}</div>
              </div>
            </div>

            <div className="event columns">
              <div className="column-2 column-left">
                <h3>Evenement</h3>
                <h1>
                  <NavLink to={`/admin/events/${match.event._id._id}`}>{match.event.name}</NavLink>
                </h1>
                <ul>
                  <li>
                    <i className="fa fa-map-marker"></i>
                    <strong>Lieu :</strong>
                    {match.event._id.city.name}
                  </li>
                  <li>
                    <i className="fa fa-clock-o"></i>
                    <strong>Date :</strong>
                    {moment(match.event._id.date).format('DD/MM/YYYY HH:mm')}
                  </li>
                  <li>
                    <i className="fa fa-clock-o"></i>
                    <strong>Durée :</strong>
                    {match.event._id.duration}
                  </li>
                  <li>
                    <i className="fa fa-users"></i>
                    <strong>Nombre de personnes :</strong>
                    {match.event._id.nbPublic}
                  </li>
                  <li>
                    <i className="fa fa-users"></i>
                    <strong>Type de public :</strong>
                    {match.event._id.publicType}
                  </li>
                  <li>
                    <i className="fa fa-sticky-note"></i>
                    <strong>Type d'intervention :</strong>
                    {match.event._id.typeOf}
                  </li>
                </ul>
              </div>
              <div className="column-2 column-right">
                <h3>Feedback</h3>
                {match.conference ? (
                  <h1>
                    <NavLink to={`/admin/feedbacks/${match.conference._id}`}>{match.conference.title}</NavLink>
                  </h1>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className="status align-center">
              <strong>Status en cours : </strong>
              {match.status}
            </div>
            <div className="statusHistoric columns">
              <div className="column-2 column-left">
                <h3>Historique des status</h3>
                <StatusList currentStatus={match.status} actions={match.actions} userType="organizer" />
              </div>
              <div className="column-2 column-right">
                <h3>Historique des status</h3>
                <StatusList currentStatus={match.status} actions={match.actions} userType="speaker" />
              </div>
            </div>

            {/*<div className='documents'>
            <h3>Documents</h3>
            <ul>
              <li>
                <i className='fa fa-folder-o'></i>
                Devis
              </li>
              <li>
                <i className='fa fa-folder-o'></i>
                Bon de commande
              </li>
              <li>
                <i className='fa fa-folder-o'></i>
                Facture
              </li>
              <li>
                <i className='fa fa-folder-o'></i>
                Fiche de paie (visible par speaker)
              </li>
            </ul>
            <div>Todo : Upload depuis backoffice</div>
          </div>*/}

            {match.status === 'proposition' ? (
              <div className="status-action">
                <RaisedButton onClick={confirm} label="Valider" className="" secondary={false}></RaisedButton>
                <RaisedButton onClick={cancel} label="Annuler" className="raised" secondary={true}></RaisedButton>
              </div>
            ) : (
              ''
            )}

            {match.status === 'canceled' ? <div className="status-action canceled">Annulé</div> : ''}

            {match.status === 'contact' ? <div className="status-action confirmed">Confirmé</div> : ''}

            <div className="chat">
              <h3>Messagerie</h3>
              <textarea type="textarea" onChange={onChangeText} value={text}></textarea>
              <RaisedButton onClick={sendMessage} label="Envoyer" className="" secondary={false}></RaisedButton>
              <Chat ref={setChatRef} matchId={match._id} />
            </div>
          </div>
        ) : (
          ''
        )}
      </Layout>
    )
  }
}

export default MatchsDetail
