import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import "./index.scss";
import services from "services";
import fake_responses from "./fake_responses.json";

import Layout from "components/Layout";
import Form from "components/Form";
import Input from "components/FormInput";

import RaisedButton from "material-ui/RaisedButton";
import Checkbox from "material-ui/Checkbox";
import SvgIcon from "material-ui/SvgIcon";

import { random } from "utils/string";

const replaceURLVariables = (url, data) => {
  return url
    .replace("[slug]", data?.slug || "")
    .replace("[name]", data?.name || "")
    .replace("[id]", data?._id || "");
};

const prompts_fr = (props, state, reinit) =>
  !reinit && !!props.data.chatgpt_prompts
    ? props.data.chatgpt_prompts
    : [
        {
          key: random(8),
          prompt: `En 15 lignes, qu'est ce que le ${props.data.name} et en quoi est ce utile en entreprise ?`,
          response: "",
        },
        // {
        //   key: random(8),
        //   prompt: `En 15 lignes, en quoi est ce utile en entreprise ?`,
        //   response: "",
        // },
        {
          key: random(8),
          prompt: `En 8 lignes d'introduction, puis 5 bullet points sur les points clés, pourquoi faire intervenir un conférencier lors d’une conférence en entreprise sur le ${props.data.name} ?`,
          response: "",
        },
        {
          key: random(8),
          prompt:
            "Peux tu mettre dans un seul message toutes mes questions précédentes et tes réponses, ajouter des balises <h2> autour de mes questions et des balises <p> autour des paragraphes de tes réponses ?",
          response: "",
        },
      ];

const prompts_en = (props, state) => [
  {
    key: random(8),
    prompt: `Peux tu traduire le texte suivant en anglais ?\n\n${state.generated_description.fr}`,
    response: "",
    style: { minHeight: 300 },
  },
];

const initialPrompts = (props, state) =>
  state.language === "fr" ? prompts_fr(props, state) : prompts_en(props, state);

class SEODetailView extends Component {
  state = {
    mode: "default", // "default" | "chatgpt"
    language: "fr",
    prompts: [],
    showChatGpt: false,
    openedPrompt: -1,
    generating: -1,
    error: -1,
    errorHexaCode: null,
    savingPrompts: -1,
    descriptionPreview: false,
    generated_description: {
      fr: "",
      en: "",
    },
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data._id && prevProps.data?._id !== this.props.data?._id) {
      this.setState({
        prompts: initialPrompts(this.props, this.state),
      });
    }
  }

  onChangeLanguage = (language, value) => {
    this.setState({
      language,
      prompts: initialPrompts(this.props, { ...this.state, language }),
    });
  };

  changeModeToChatGpt = () => {
    this.setState({
      mode: "chatgpt",
      generated_description: this.props.data.page_description || {
        fr: "",
        en: "",
      },
    });
    window.scrollTo({
      top: 0,
    });
  };

  changeModeToDefault = () => {
    this.setState({
      mode: "default",
      generated_description: { fr: "", en: "" },
    });
  };

  openOrClose = (index) => () => {
    const { openedPrompt } = this.state;
    this.setState({ openedPrompt: index === openedPrompt ? -1 : index });
  };

  addPrompt = (index) => () => {
    console.log("add prompt : ", index);
    const newPrompt = {
      key: random(8),
      prompt: "",
      response: "",
    };
    const prompts = [
      ...this.state.prompts.slice(0, index),
      newPrompt,
      ...this.state.prompts.slice(index),
    ];
    this.setState({ prompts });
  };

  reinitPrompts = () => {
    const { language } = this.state;
    this.setState({
      prompts: prompts_fr(this.props, this.state, true),
    });
  };

  removePrompt = (index) => () => {
    this.setState({
      prompts: this.state.prompts.filter((p, i) => i !== index),
    });
  };

  onChangePrompt = (index) => (event) => {
    const prompts = this.state.prompts.map((p, i) => {
      if (index !== i) {
        return p;
      }
      return { ...p, prompt: event.target.value };
    });
    this.setState({ prompts });
  };

  onChangeResponse = (index) => (event) => {
    const prompts = this.state.prompts.map((p, i) => {
      if (index !== i) {
        return p;
      }
      return { ...p, response: event.target.value };
    });
    this.setState({ prompts });
  };

  generate = (prompt, index) => async () => {
    console.log("GENERATE : ", index, prompt);
    if (this.state.generating !== -1) {
      console.error("already generating !");
      return;
    }
    const { language } = this.state;
    this.setState({ error: -1, errorHexaCode: null, generating: index });

    const prompts = this.state.prompts.slice(0, index + 1);
    prompts[prompts.length - 1].response = "";
    try {
      const result = await services.generateChatGptForTag.create({
        tagId: this.props.data._id,
        shouldSavePrompt: language === "fr",
        prompts,
      });
      const newPrompts = this.state.prompts.map((p, i) => {
        if (index !== i) {
          return p;
        }
        return { ...p, response: result.response };
      });
      this.setState({ generating: -1, prompts: newPrompts });
    } catch (err) {
      this.setState({
        error: index,
        errorHexaCode: err.hexaCode,
        generating: -1,
      });
    }
  };

  copyResponse = (response) => () => {
    const { language } = this.state;
    this.setState({
      generated_description: {
        ...this.state.generated_description,
        [language]: response,
      },
    });
  };

  changeGeneratedDescription = (event) => {
    const { language } = this.state;
    this.setState({
      generated_description: {
        ...this.state.generated_description,
        [language]: event.target.value,
      },
    });
  };

  useDescription = () => {
    const { onChange } = this.props;
    const { generated_description } = this.state;
    onChange("page_description", null, generated_description);
    this.setState({ mode: "default" });
    window.scrollTo({
      top: 0,
    });
  };

  savePrompts = (index) => async () => {
    const { prompts } = this.state;
    const id = this.props.data._id;
    console.log("SAVE PROMPTS");
    if (this.state.savingPrompts !== -1) {
      console.error("already savingPrompts !");
      return;
    }
    try {
      this.setState({ savingPrompts: index });
      await services.savePromptsForTag(id).create({ prompts });
      this.setState({ savingPrompts: -1 });
    } catch (err) {
      this.setState({ savingPrompts: -1, error: index });
      console.error(err);
    }
  };

  hideDescriptionPreview = () => {
    this.setState({ descriptionPreview: false });
  };

  showDescriptionPreview = () => {
    this.setState({ descriptionPreview: true });
  };

  showChatGpt = (value) => () => {
    this.setState({ showChatGpt: value });
  };

  renderPrompts = () => {
    const {
      error,
      errorHexaCode,
      generating,
      openedPrompt,
      savingPrompts,
      language,
      prompts,
      showChatGpt,
      generated_description,
      descriptionPreview,
    } = this.state;
    const { container, forms, data, onChange, onSubmit } = this.props;
    const { title } = container;
    if (!showChatGpt) {
      return (
        <div className="actions">
          <RaisedButton
            label={"Utiliser ChatGPT"}
            primary={true}
            onClick={this.showChatGpt(true)}
          ></RaisedButton>
        </div>
      );
    }

    return (
      <div className="form" style={{ marginBottom: 80 }}>
        <div className="actions">
          <RaisedButton
            label={"Fermer ChatGPT"}
            primary={true}
            onClick={this.showChatGpt(false)}
          ></RaisedButton>
        </div>

        {/* <h1>Prompts à envoyer à ChatGPT :</h1>
        <div className="actions">
          <RaisedButton
            label={
              savingPrompts === 99
                ? "Sauvegarde en cours"
                : "Sauvegarder pour plus tard"
            }
            disabled={savingPrompts === 99}
            primary={true}
            onClick={this.savePrompts(99)}
          ></RaisedButton>
        </div> */}
        {prompts.map((p, index) => {
          if (openedPrompt !== index) {
            // CLOSED STATE
            return (
              <div className="prompt" key={p.key}>
                <div
                  className="prompt-frame clickable"
                  onClick={this.openOrClose(index)}
                >
                  <div>
                    <div className="checked-status">
                      <Checkbox checked={!!p.response} onCheck={(e, v) => {}} />
                    </div>
                    <label className="inline clickable">
                      Prompt {index + 1}
                    </label>{" "}
                    :{" "}
                    {p.prompt.slice(0, 150) +
                      (p.prompt.length > 150 ? "..." : "")}
                  </div>
                </div>
                {language === "fr" && (
                  <div className="actions">
                    <RaisedButton
                      label={"Ajouter"}
                      onClick={this.addPrompt(index + 1)}
                    ></RaisedButton>
                    <RaisedButton
                      label={"Supprimer"}
                      onClick={this.removePrompt(index)}
                    ></RaisedButton>
                  </div>
                )}
              </div>
            );
          }
          // OPENED STATE
          return (
            <div className="prompt" key={p.key}>
              <div
                className="prompt-frame clickable opened-top"
                onClick={this.openOrClose(index)}
              >
                <div>
                  <label className="inline clickable">Prompt {index + 1}</label>{" "}
                  :{" "}
                </div>
              </div>
              <div className="prompt-frame opened-bottom">
                <div className="form-group">
                  <textarea
                    type="textarea"
                    placeholder={`Prompt ${index + 1}`}
                    onChange={this.onChangePrompt(index)}
                    value={p.prompt}
                    style={p.style ? p.style : undefined}
                  ></textarea>
                </div>
                {p.prompt.length > 3 && (
                  <div className="actions">
                    <div>
                      {!p.response && (
                        <strong>
                          Attention ! les prompts & réponses précédentes ont un
                          impact sur la suite de la génération
                        </strong>
                      )}
                    </div>

                    <RaisedButton
                      label={
                        generating === index
                          ? "Generation de la réponse en cours ..."
                          : !p.response
                          ? "Générer la réponse"
                          : "Générer une nouvelle réponse"
                      }
                      disabled={generating > -1}
                      primary={true}
                      onClick={this.generate(p, index)}
                    ></RaisedButton>
                    {error === index && (
                      <div className="error">
                        Une erreur s'est produite lors de la génération
                        {errorHexaCode
                          ? ` - Code erreur : #${errorHexaCode}`
                          : ""}
                      </div>
                    )}
                  </div>
                )}
                <div className="response">
                  <div className="form-group">
                    <span>
                      <label>
                        Response {index + 1}{" "}
                        <span>
                          (Il est possible de modifier la réponse manuellement)
                        </span>
                      </label>
                      <textarea
                        type="textarea"
                        placeholder={`Réponse ${index + 1}`}
                        onChange={this.onChangeResponse(index)}
                        value={p.response || ""}
                        style={{ minHeight: 200 }}
                      ></textarea>
                    </span>
                  </div>
                  {p.response?.length > 3 && index === prompts.length - 1 && (
                    <div className="actions">
                      <RaisedButton
                        label={
                          "Copier cette dernière réponse dans ma description de page"
                        }
                        primary={true}
                        onClick={this.copyResponse(p.response)}
                      ></RaisedButton>
                    </div>
                  )}
                </div>
              </div>

              {language === "fr" && (
                <div className="actions">
                  <RaisedButton
                    label={"Ajouter"}
                    onClick={this.addPrompt(index + 1)}
                  ></RaisedButton>
                  <RaisedButton
                    label={"Supprimer"}
                    onClick={this.removePrompt(index)}
                  ></RaisedButton>
                </div>
              )}
            </div>
          );
        })}
        {language === "fr" && (
          <div className="actions">
            {prompts.length > 0 ? (
              <>
                <RaisedButton
                  label={
                    savingPrompts === 99
                      ? "Sauvegarde en cours"
                      : "Sauvegarder pour plus tard"
                  }
                  disabled={savingPrompts === 99}
                  primary={true}
                  onClick={this.savePrompts(99)}
                ></RaisedButton>
                <RaisedButton
                  label={"Reinitialiser"}
                  onClick={this.reinitPrompts}
                ></RaisedButton>
              </>
            ) : (
              <>
                <RaisedButton
                  label={"Ajouter un prompt"}
                  onClick={this.addPrompt(0)}
                ></RaisedButton>
                <RaisedButton
                  label={"Reinitialiser"}
                  onClick={this.reinitPrompts}
                ></RaisedButton>
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  renderChatGpt = () => {
    const {
      error,
      errorHexaCode,
      generating,
      openedPrompt,
      savingPrompts,
      language,
      prompts,
      generated_description,
      descriptionPreview,
    } = this.state;
    const { container, forms, data, onChange, onSubmit } = this.props;
    const { title } = container;
    return (
      <Layout {...this.props} title={title ? title : "SEO Detail"}>
        <div className="seo-detail-page detail-page chatgpt">
          <div className="actions">
            <RaisedButton
              label={"Annuler / Revenir en arrière"}
              primary={false}
              onClick={this.changeModeToDefault}
            ></RaisedButton>
          </div>

          <section>{this.renderPrompts()}</section>

          {/* DESCRIPTION PREVIEW */}
          <section>
            <div className="language-selection">
              <Checkbox
                label={"Français"}
                checked={language === "fr"}
                onCheck={(e, v) => {
                  this.onChangeLanguage("fr", v);
                }}
              />

              <Checkbox
                label={"Anglais"}
                checked={language === "en"}
                onCheck={(e, v) => {
                  this.onChangeLanguage("en", v);
                }}
              />
            </div>

            <h1>DESCRIPTION DE PAGE :</h1>

            <div>
              <div className="form">
                <span key={language}>
                  <label>Description de page ({language.toUpperCase()})</label>
                  <textarea
                    type="textarea"
                    onChange={this.changeGeneratedDescription}
                    value={generated_description[language]}
                    style={{ minHeight: 500 }}
                  ></textarea>
                </span>
              </div>
              <div className="actions">
                {!descriptionPreview ? (
                  <RaisedButton
                    label={"Afficher l'aperçu"}
                    primary={false}
                    onClick={this.showDescriptionPreview}
                  ></RaisedButton>
                ) : (
                  <RaisedButton
                    label={"Masquer l'aperçu"}
                    primary={false}
                    onClick={this.hideDescriptionPreview}
                  ></RaisedButton>
                )}
              </div>
            </div>
            {!!descriptionPreview && (
              <div>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: generated_description[language],
                  }}
                ></div>
              </div>
            )}
            <div className="actions">
              <RaisedButton
                label={"Utiliser cette description"}
                primary={true}
                onClick={this.useDescription}
              ></RaisedButton>
              <RaisedButton
                label={"Annuler / Revenir en arrière"}
                primary={false}
                onClick={this.changeModeToDefault}
              ></RaisedButton>
            </div>
          </section>
        </div>
      </Layout>
    );
  };

  renderDefault = () => {
    const { language } = this.state;
    const { container, forms, data, onChange, onSubmit } = this.props;
    const { title } = container;
    const FRONTEND_URL =
      language === "en"
        ? container.FRONTEND_URLS?.EN
        : container.FRONTEND_URLS?.FR;
    const BACKOFFICE_URL =
      language === "en"
        ? container.BACKOFFICE_URLS?.EN
        : container.BACKOFFICE_URLS?.FR;

    if (!data?._id) {
      return (
      <Layout {...this.props} title={title ? title : "SEO Detail"}>
        <div className="seo-detail-page detail-page">
          Ce mot clé n'existe pas
        </div>
      </Layout>
      )
    }

    return (
      <Layout {...this.props} title={title ? title : "SEO Detail"}>
        <div className="seo-detail-page detail-page">
          <Form
            schema={forms.raw}
            data={data}
            onChange={onChange}
            onSubmit={onSubmit}
            hideSubmitButton={true}
          />

          <div className="language-selection" style={{ marginTop: 80 }}>
            <Checkbox
              label={"Français"}
              checked={language === "fr"}
              onCheck={(e, v) => {
                this.onChangeLanguage("fr", v);
              }}
            />

            <Checkbox
              label={"Anglais"}
              checked={language === "en"}
              onCheck={(e, v) => {
                this.onChangeLanguage("en", v);
              }}
            />
          </div>

          {!!FRONTEND_URL && (
            <div className="page-link">
              <div>
                <strong>URL PUBLIC :</strong>
              </div>
              <a target="_blank" href={replaceURLVariables(FRONTEND_URL, data)}>
                {replaceURLVariables(FRONTEND_URL, data)}
              </a>
            </div>
          )}

          {!!BACKOFFICE_URL && (
            <div className="page-link">
              <div>
                <strong>URL BACKOFFICE :</strong>
              </div>
              <a
                target="_blank"
                href={replaceURLVariables(BACKOFFICE_URL, data)}
              >
                {replaceURLVariables(BACKOFFICE_URL, data)}
              </a>
            </div>
          )}

          <div className="actions">
            <RaisedButton
              label={"Modifier la description"}
              primary={false}
              onClick={this.changeModeToChatGpt}
            ></RaisedButton>
          </div>

          {language === "fr" ? (
            <Form
              schema={forms.fr}
              data={data}
              onChange={onChange}
              onSubmit={onSubmit}
              hideSubmitButton={true}
            />
          ) : (
            <Form
              schema={forms.en}
              data={data}
              onChange={onChange}
              onSubmit={onSubmit}
              hideSubmitButton={true}
            />
          )}

          <div className="actions">
            <RaisedButton
              label={"Modifier la description"}
              primary={false}
              onClick={this.changeModeToChatGpt}
            ></RaisedButton>
          </div>

          <div className="language-selection">
            <Checkbox
              label={"Français"}
              checked={language === "fr"}
              onCheck={(e, v) => {
                this.onChangeLanguage("fr", v);
              }}
            />

            <Checkbox
              label={"Anglais"}
              checked={language === "en"}
              onCheck={(e, v) => {
                this.onChangeLanguage("en", v);
              }}
            />
          </div>

          <div className="actions">
            <RaisedButton
              label={"Sauvegarder"}
              primary={true}
              onClick={onSubmit}
            ></RaisedButton>
          </div>
        </div>
      </Layout>
    );
  };

  render() {
    const { mode } = this.state;

    return mode === "default" ? this.renderDefault() : this.renderChatGpt();
  }
}

export default SEODetailView;
