import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import './style.css'

import RaisedButton from 'material-ui/RaisedButton'

import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton'
import Checkbox from 'material-ui/Checkbox';
import Layout from 'components/Layout'
import Table from 'components/Table'
import Pagination from 'components/Pagination'
import Filters from 'components/Filters'
import TotalResult from 'components/TotalResult'
import download from 'downloadjs'

class Users extends Component {

  render() {
    const {
      container,
      columns,
      data,
      total,
      currentPage,
      onChangePage,
      filters,
      onChangeFilters,
      location
    } = this.props

    return (
      <Layout {...this.props} title='Liste des utilisateurs'>
        <div className='users-page'>
          <div className='block'>
            <Filters tree={filters} onChange={onChangeFilters} />
            <div>
              <Checkbox
                onClick={container.onChangeShowTags}
                checked={!!container.showTags}
                label={'Filtrer par critère'}
              />
              <div className='criterias'>
                { container.showTags && container.criterias.map(c => (
                  <span className='criteria' key={c} onClick={() => container.filterCriteria(c)} className={container.criteriasSelected.indexOf(c) !== -1 ? 'active': ''}>{c}</span>
                ))}
              </div>
            </div>
            <Pagination forcePage={currentPage}
                        pageCount={total / 30}
                        onPageChange={onChangePage}/>
            <TotalResult label='utilisateurs' total={total}/>
            {/*<NavLink className='create-user' to='/admin/users/create'><RaisedButton className='raised' secondary={true}>Creer un utilisateur</RaisedButton></NavLink>*/}
            <RaisedButton className='export raised' secondary={true} onClick={container.onExport}>export</RaisedButton>
            <RaisedButton className='export-csv raised' secondary={true} onClick={container.onExportCsv}>export CSV</RaisedButton>
            <Table columns={columns}
                   data={data} 
                   location={location}
                   />
            <Pagination forcePage={currentPage}
                        pageCount={total / 30}
                        onPageChange={onChangePage}/>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Users