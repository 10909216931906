import moment from 'moment'
import get from 'lodash/get'

export const humanizeDate = dateStr => {
  if (!dateStr) {
    return null
  }
  const date = moment(dateStr, 'YYYY-MM-DD')
  if (!date.isValid()) {
    return null
  }

  const yesterday = moment()
    .subtract(1, 'days')
    .startOf('day')
  const today = moment().startOf('day')
  const tomorrow = moment()
    .add(1, 'days')
    .startOf('day')
  const year = !isSameYear(today, date) ? 'YYYY' : ''

  if (isSameDate(today, date)) {
    return 'Today'
  } else if (isSameDate(tomorrow, date)) {
    return 'Tomorrow'
  } else if (isSameDate(yesterday, date)) {
    return 'Yesterday'
  } else if (isSameWeek(today, date)) {
    return date.from(today)
  } else if (isBefore(date, today)) {
    return date.format(`ddd DD MMMM ${year}`)
  }
  return date.format(`ddd DD MMMM ${year}`)
}

export const timeAgo = (dateStr, maxDiff = 7) => {
  if (!dateStr) {
    return ''
  }
  const today = moment()
  const diff = today.diff(dateStr, 'days')

  if (diff <= maxDiff) {
    return moment(dateStr).fromNow()
  }
  return humanizeDate(dateStr)
}

export const isToday = dateStr => {
  if (!dateStr) {
    return false
  }
  const date = moment(dateStr)

  return isSameDate(date, moment())
}

export const isTomorrow = dateStr => {
  if (!dateStr) {
    return false
  }
  const date = moment(dateStr)
  const tomorrow = moment()
    .add(1, 'days')
    .startOf('day')

  return isSameDate(date, tomorrow)
}

export const isYesterday = dateStr => {
  if (!dateStr) {
    return false
  }
  const date = moment(dateStr)
  const yesterday = moment()
    .subtract(1, 'days')
    .startOf('day')

  return isSameDate(date, yesterday)
}

export const isSameDate = (date1, date2) => {
  return date1.isSame(date2, 'day') && date1.isSame(date2, 'month') && date1.isSame(date2, 'year')
}

export const isSameWeek = (date1, date2) => {
  return date1.isSame(date2, 'week') && date1.isSame(date2, 'month') && date1.isSame(date2, 'year')
}

export const isSameYear = (date1, date2) => {
  return date1.isSame(date2, 'year')
}

export const isBefore = (date1, date2) => {
  return date1.isBefore(date2, 'day')
}

export const dateAtTime = dateStr => {
  const d = moment(dateStr)
  const date = d.format('DD/MM/YY')
  const time = d.format('HH:mm')
  return `${date} à ${time}`
}
export const sortConversations = (a, b) => {
  const dateA = get(a, 'lastMessage.createdAt') || a.updatedAt
  const dateB = get(b, 'lastMessage.createdAt') || b.updatedAt
  return new Date(dateB).getTime() - new Date(dateA).getTime()
}
