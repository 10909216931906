import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import "./index.scss";

import Layout from "components/Layout";
import Form from "components/Form";

import Checkbox from "material-ui/Checkbox";

const replaceURLVariables = (url, data) => {
  return url
    .replace("[slug]", data?.slug || "")
    .replace("[name]", data?.name || "")
    .replace("[id]", data?._id || "");
};

class SEODetailView extends Component {
  state = {
    language: "fr",
  };

  onChangeLanguage = (language, value) => {
    this.setState({ language });
  };
  render() {
    const { language } = this.state;
    const { container, forms, data, onChange, onSubmit } = this.props;
    const { title } = container;
    const FRONTEND_URL =
      language === "en"
        ? container.FRONTEND_URLS?.EN
        : container.FRONTEND_URLS?.FR;
    const BACKOFFICE_URL =
      language === "en"
        ? container.BACKOFFICE_URLS?.EN
        : container.BACKOFFICE_URLS?.FR;

    return (
      <Layout {...this.props} title={title ? title : "SEO Detail"}>
        <div className="seo-detail-page detail-page">
          {/* in case user is not visible publicly */}
          {!!data?.email && data?.__not_public && (
            <div className="warning-box">
              <div className="warning-box-message">
                Ce speaker n'est pas visible publiquement
              </div>
              <div className="warning-box-message-explanation">
                Possibles raisons : Pas de talks, pas de description de profil
              </div>
            </div>
          )}
          <Form
            schema={forms.raw}
            data={data}
            onChange={onChange}
            onSubmit={onSubmit}
            hideSubmitButton={true}
          />

          <div className="language-selection">
            <Checkbox
              label={"Français"}
              checked={language === "fr"}
              onCheck={(e, v) => {
                this.onChangeLanguage("fr", v);
              }}
            />

            <Checkbox
              label={"Anglais"}
              checked={language === "en"}
              onCheck={(e, v) => {
                this.onChangeLanguage("en", v);
              }}
            />
          </div>

          {!!FRONTEND_URL && (
            <div className="page-link">
              <div>
                <strong>URL PUBLIC :</strong>
              </div>
              <a target="_blank" href={replaceURLVariables(FRONTEND_URL, data)}>
                {replaceURLVariables(FRONTEND_URL, data)}
              </a>
            </div>
          )}

          {!!BACKOFFICE_URL && (
            <div className="page-link">
              <div>
                <strong>URL BACKOFFICE :</strong>
              </div>
              <a
                target="_blank"
                href={replaceURLVariables(BACKOFFICE_URL, data)}
              >
                {replaceURLVariables(BACKOFFICE_URL, data)}
              </a>
            </div>
          )}

          {language === "fr" ? (
            <Form
              schema={forms.fr}
              data={data}
              onChange={onChange}
              onSubmit={onSubmit}
            />
          ) : (
            <Form
              schema={forms.en}
              data={data}
              onChange={onChange}
              onSubmit={onSubmit}
            />
          )}
        </div>
      </Layout>
    );
  }
}

export default SEODetailView;
