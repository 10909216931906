import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { WithContext as ReactTags } from 'react-tag-input'
import './style.css'
import find from 'lodash/find'

import { onDelay } from 'utils'
import { users } from 'services'

class TagsInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      suggestions: [],
    }
    this.handleDelete = this.handleDelete.bind(this)
    this.handleAddition = this.handleAddition.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.fetch = this.fetch.bind(this)
  }

  fetch(q) {
    onDelay(200, 'searchUsers', () => {
      users
        .find({
          query: {
            q: q || '',
            limit: 6,
            select: 'firstname lastname email',
          },
        })
        .then((result) => {
          console.log(result)
          this.setState({
            suggestions: result.data.map((sugg) => {
              return {
                id: sugg._id,
                text: `${sugg.firstname} ${sugg.lastname}`,
                tag: `${q} : ${sugg.firstname} ${sugg.lastname} (${sugg.email})`,
              }
            }),
          })
        })
        .catch((e) => {
          console.error(e)
        })
    })
  }

  handleInputChange(v) {
    this.fetch(v)
  }

  handleDelete(i) {
    if (!this.props.onChange) {
      return
    }
    this.props.onChange(this.props.name, null, null)
  }

  handleAddition(tag) {
    tag = find(this.state.suggestions, ['tag', tag])
    if (!this.props.onChange) {
      return
    }
    this.props.onChange(this.props.name, null, tag)
  }

  render() {
    const tags = this.props.value ? [this.props.value] : []
    const suggestions = this.state.suggestions.map((s) => s.tag)

    return (
      <ReactTags
        tags={tags}
        classNames={{ tagInput: tags.length > 0 ? 'ReactTags__tagInput display-none': 'ReactTags__tagInput' }}
        placeholder="Ajouter un utilisateur"
        suggestions={suggestions}
        handleInputChange={this.handleInputChange}
        handleDelete={this.handleDelete}
        handleAddition={this.handleAddition}
      />
    )
  }
}

TagsInput.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  tags: PropTypes.array,
}

export default TagsInput
