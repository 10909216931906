import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { handleJsonLink } from "utils";
import _ from "lodash";
import "./style.css";
import OpenIcon from "material-ui/svg-icons/action/search";

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from "material-ui/Table";

class CustomTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowSelected: -1,
      globalStyleHeader: {
        textOverflow: "initial",
        whiteSpace: "initial",
        paddingLeft: "4px",
        paddingRight: "4px",
      },
      globalStyleBody: {
        textOverflow: "initial",
        whiteSpace: "initial",
        paddingLeft: "4px",
        paddingRight: "10px",
      },
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.data !== nextProps.data ||
      this.props.column !== nextProps.columns
    );
  }

  renderAction(column, item) {
    switch (column.action) {
      case "open":
        return <OpenIcon />;
    }
    return "";
  }

  renderImg(column, item) {
    let value = _.get(item, column.prop);
    if (!value) {
      return "";
    }
    if (value.indexOf("http") == -1) {
      value = process.env.REACT_APP_SERVER + "/public" + value;
    }
    return <img className="img" src={value} />;
  }

  renderDate(column, item) {
    let value = _.get(item, column.prop);
    return moment(value).format("DD/MM/YYYY");
  }

  renderDateTime(column, item) {
    let value = _.get(item, column.prop);
    let date = moment(value).format("DD/MM/YYYY");
    let time = moment(value).format("HH:mm:ss");
    return (
      <div>
        <div>{date}</div>
        <div>{time}</div>
      </div>
    );
  }

  renderText(column, item) {
    let value = "";
    let prop = column.prop;
    if (Array.isArray(prop)) {
      prop.forEach((p, index) => {
        if (index > 0) {
          value += " ";
        }
        value += _.get(item, p) || "";
      });
    } else {
      value = _.get(item, prop);
    }

    /* in case the item[prop] is an array
     ** (example : user[typeOf] = [speaker, organizer])
     */
    if (Array.isArray(value)) {
      value = value.join(", ");
    }
    if (_.get(value, "trim")) {
      value = value.trim();
    }
    value = value || column.default || "";

    return (
      <span>
        {column.icon && item[column.iconProp] ? (
          <i
            style={{ marginRight: 5, fontSize: 18 }}
            className={column.icon}
          ></i>
        ) : (
          ""
        )}{" "}
        {value}
      </span>
    );
  }

  renderBoolean(column, item) {
    let prop = column.prop;

    return (!!_.get(item, prop) || false).toString();
  }

  renderTrueOrNothing(column, item) {
    let prop = column.prop;

    return !!_.get(item, prop) ? "true" : "";
  }

  renderInverseBoolean(column, item) {
    let prop = column.prop;

    return (!_.get(item, prop) || false).toString();
  }

  renderList(column, item) {
    if (!column.list || !Array.isArray(column.list)) {
      return this.renderText(column, item);
    }
    const isActive = (el) => {
      if (item[column.prop] === el) {
        return true;
      }

      if (
        Array.isArray(item[column.prop]) &&
        item[column.prop].indexOf(el) !== -1
      ) {
        return true;
      }

      return false;
    };
    let value = (
      <div>
        {column.list.map((el) => (
          <span
            key={el}
            onClick={() => (column.onClick ? column.onClick(item, el) : null)}
            className={isActive(el) ? "active" : ""}
          >
            {el}
          </span>
        ))}
      </div>
    );
    return value;
  }

  renderColumnBody(column, item, index) {
    const { globalStyleBody } = this.state;
    let style = column.style
      ? Object.assign({}, globalStyleBody, column.style)
      : globalStyleBody;
    let className = column.className ? column.className : "";
    let link;
    if (column.link) {
      link = handleJsonLink(item, column.link);
    } else if (column.url) {
      link = _.get(item, column.url);
    }

    let renderValue;
    switch (column.type) {
      case "img":
        renderValue = this.renderImg;
        break;
      case "action":
        renderValue = this.renderAction.bind(this);
        break;
      case "date":
        renderValue = this.renderDate;
        break;
      case "datetime":
        renderValue = this.renderDateTime;
        break;
      case "boolean":
        renderValue = this.renderBoolean;
        break;
      case "!boolean":
        renderValue = this.renderInverseBoolean;
        break;
      case "true_or_nothing":
        renderValue = this.renderTrueOrNothing;
        break;
      case "list":
        renderValue = this.renderList.bind(this);
        break;
      default:
        renderValue = this.renderText;
    }
    return (
      <TableRowColumn
        className={className}
        style={style}
        key={`column-${index}`}
      >
        {!column.link && !column.url ? renderValue(column, item) : ""}
        {column.link ? (
          <NavLink to={`${link}`}>{renderValue(column, item)}</NavLink>
        ) : (
          ""
        )}
        {column.url ? <a href={`${link}`}>{renderValue(column, item)}</a> : ""}
      </TableRowColumn>
    );
  }

  render() {
    const { columns, data, onRowSelection } = this.props;
    const { globalStyleHeader, rowSelected } = this.state;
    let selectable = this.props.selectable || false;
    let className = "";
    if (!selectable) {
      className += "unselectable";
    }
    let onRowSelectionWrapper;
    if (onRowSelection) {
      onRowSelectionWrapper = (i) => {
        /* array of indexes */
        i = i[0];
        this.setState({
          rowSelected: i,
        });
        onRowSelection(data[i]);
      };
    }
    return (
      <Table
        className={className}
        selectable={selectable}
        onRowSelection={onRowSelectionWrapper}
      >
        <TableHeader>
          <TableRow selectable={selectable}>
            {columns.map((column, index) => {
              let style = column.style
                ? Object.assign({}, globalStyleHeader, column.style)
                : globalStyleHeader;
              let className = column.className ? column.className : "";
              return (
                <TableHeaderColumn
                  style={style}
                  key={`header-column-${index}`}
                  className={className}
                >
                  {column.label}
                </TableHeaderColumn>
              );
            })}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((item, index) => {
            let selected = rowSelected === index;
            return (
              <TableRow
                className={"table-row " + item.status}
                key={`row-${index}`}
                selected={selected}
              >
                {columns.map((column, index) => {
                  return this.renderColumnBody(column, item, index);
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

CustomTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  location: PropTypes.object,
  onRowSelection: PropTypes.func,
  selectable: PropTypes.bool,
};

export default CustomTable;
