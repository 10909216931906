import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import Layout from 'components/Layout'
import Table from 'components/Table'
import Pagination from 'components/Pagination'
import Filters from 'components/Filters'
import TotalResult from 'components/TotalResult'

class FeedbacksView extends Component {
  render() {
    const {
      columns,
      data,
      total,
      currentPage,
      onChangePage,
      filters,
      onChangeFilters,
      location
    } = this.props
    return (
      <Layout {...this.props} title='Liste des feedbacks'>
        <div className='feedbacks-page'>
          {/*<div className='block feedback-week-emarketing'>
            <div className='mgb10'>Feedback de la semaine :</div>
            <div>
              Emarketing : <NavLink to={`/admin/feedbacks/${this.props.container['weeklyFeedback']}`}>Cliquez ici</NavLink>
            </div>
          </div>*/}
          <div className='block'>
            <Filters tree={filters} onChange={onChangeFilters} />
            <Pagination forcePage={currentPage}
                        pageCount={total / 30}
                        onPageChange={onChangePage}/>
            <TotalResult label='feedbacks' total={total}/>
            <Table columns={columns}
                   data={data} 
                   location={location}
                   />
            <Pagination forcePage={currentPage}
                        pageCount={total / 30}
                        onPageChange={onChangePage}/>
          </div>
        </div>
      </Layout>
    )
  }
}

export default FeedbacksView