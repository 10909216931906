import React, { Component } from 'react'
import PropTypes from 'prop-types'
import statusList from 'constants/statusList'
import _ from 'lodash'
import messages from 'messages.json'
import './style.css'

class StatusList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      matchStatusIndex: statusList.indexOf(this.props.currentStatus)
    }
  }

  render() {
    const { currentStatus, userType, actions } = this.props
    const { matchStatusIndex } = this.state
    return (
      <div className='status-list'>
        <ul>
        {
          statusList.map((status, index) => {
            let isPending = (index === matchStatusIndex)
            let isCompleted = ((index < matchStatusIndex) || _.get(actions, `${status}.${userType}.value`))
            let className = ''
            let statusDetail = messages.statusDetail[userType][status].default
            if (isPending) {
              className += 'pending '
            }
            if (isCompleted) {
              className += 'active '
              if (messages.statusDetail[userType][status].pending) {
                statusDetail = messages.statusDetail[userType][status].pending
              }
            }
            return (
              <li key={status} className={className}>
                { isCompleted ? <i className='fa fa-check'></i> : <i className='fa fa-clock-o'></i> }
                <strong>{status} :</strong> {statusDetail}
              </li>
            )
          })
        }
        </ul>
      </div>
    )
  }
}

StatusList.propTypes = {
  currentStatus: PropTypes.string,
  actions: PropTypes.object,
  userType: PropTypes.string
}

export default StatusList
