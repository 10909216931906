import { combineReducers } from 'redux'
import session from './session'
import snackbar from './snackbar'

const rootReducer = combineReducers({
    session,
    snackbar
})

export default rootReducer
