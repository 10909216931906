import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from 'actions/session'

import Login from './component'

import { auth } from 'services'

const mapStateToProps = state => ({
  session: state.session
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

class LoginContainer extends Component {

  constructor (props) {
    super(props)
    this.state = {
      error: null,
      form: {
        email: '',
        password: ''
      }
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onChange(key, e) {
    let value = e.target.value
    this.setState({
      form: {
        ...this.state.form,
        [key]: value
      }
    })
  }

  onSubmit (e) {
    e.preventDefault()
    const { email, password } = this.state.form
    auth.create({
      email,
      password
    }).then(result => {
      const token = {
        token: result.token.accessToken,
        refresh: result.token.refreshToken
      }
      const user = {...result.user, ...token}
      localStorage.setItem('user', JSON.stringify(user))
      this.props.actions.setUser(user)
      console.log(result)
    }).catch(e => {
      console.error(e)
      this.setState({
        error: 'Login or Password incorrect'
      })
    })
  }

  render () {
    return (
      <Login onSubmit={this.onSubmit}
             error={this.state.error}
             form={this.state.form}
             onChange={this.onChange}/>
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: true })(LoginContainer)
