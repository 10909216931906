import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import find from "lodash/find";
import get from "lodash/get";
import moment from "moment";
import { handleJsonLink } from "utils";
import "./style.css";
import "react-datepicker/dist/react-datepicker.css";

import Input from "components/FormInput";
import TagsInput from "components/TagsInput";
import UsersInput from "components/UsersInput";
import UserInput from "components/UserInput";
import SelectionsInput from "components/SelectionsInput";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Dropzone from "react-dropzone";

import RaisedButton from "material-ui/RaisedButton";
import Checkbox from "material-ui/Checkbox";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

export const FormInput = ({ schema, value, onChange }) => {
  return (
    <span key={`input-${schema.label}`}>
      <label>{schema.label}</label>
      <Input
        value={value || ""}
        placeholder={schema.placeholder}
        onChange={(e) => {
          onChange(schema.key, e);
        }}
      ></Input>
    </span>
  );
};

export const FormInputNumber = ({ schema, value, onChange }) => {
  return (
    <span key={`input-number-${schema.label}`}>
      <label>{schema.label}</label>
      <Input
        type="number"
        placeholder={schema.placeholder}
        value={value}
        onChange={(e) => {
          onChange(schema.key, e);
        }}
      ></Input>
    </span>
  );
};

const FormCheckbox = ({ schema, value, onChange }) => {
  let trueValue = schema.trueValue || true;
  return (
    <span key={`checkbox-${schema.label}`}>
      <Checkbox
        label={schema.label}
        checked={value == trueValue}
        onCheck={(e, v) => {
          onChange(schema.key, null, v);
        }}
      />
    </span>
  );
};

const FormTextarea = ({ schema, value, onChange }) => {
  return (
    <span key={`checkbox-${schema.label}`}>
      <label>{schema.label}</label>
      <textarea
        type="textarea"
        onChange={(e) => {
          onChange(schema.key, e);
        }}
        value={value || ""}
      ></textarea>
    </span>
  );
};

const FormDatePicker = ({ schema, value, onChange }) => {
  return (
    <span key={`datepicker-${schema.label}`}>
      <label>{schema.label}</label>
      <DatePicker
        selected={value}
        dateFormat={"dd/MM/yyyy"}
        onChange={(date) => onChange(schema.key, null, date)}
      />
    </span>
  );
};

const FormTimePicker = ({ schema, value, onChange }) => {
  return (
    <span key={`timepicker-${schema.label}`}>
      <label>{schema.label}</label>
      <Input
        type="time"
        onChange={(e) => {
          onChange(schema.key, e);
        }}
        value={value}
      />
    </span>
  );
};

const FormSelect = ({ schema, value, onChange }) => {
  const options = schema.options.map((opt) => {
    if (opt && opt.label && opt.value) {
      return opt;
    }
    return { label: opt, value: opt };
  });
  const selectValue = find(options, ["value", value]) || null;
  return (
    <span key={`select-${schema.label}`}>
      <label>{schema.label}</label>
      {/*<SelectField
        value={value}
        onChange={(e, key, value) => {
          onChange(schema.key, null, value)
        }}>
        {schema.options.map(opt => {
          const label = opt && opt.label ? opt.label : opt
          const value = opt && opt.value ? opt.value : opt
          return <MenuItem key={value} value={value} primaryText={label} />
        })}
      </SelectField>*/}
      <Select
        className={`form-select-container ${schema.key}`}
        classNamePrefix="form-select"
        isSearchable={false}
        options={options}
        onChange={(data) => onChange(schema.key, null, data.value)}
        value={selectValue}
      />
    </span>
  );
};

const FormSelectMultiple = ({ schema, value, onChange }) => {
  const options = schema.options.map((opt) => {
    if (opt && opt.label && opt.value) {
      return opt;
    }
    return { label: opt, value: opt };
  });
  const values = !!value ? value.map((v) => find(options, ["value", v])) : [];
  return (
    <span key={`select-${schema.label}`}>
      <label>{schema.label}</label>
      {/*<SelectField
        multiple={true}
        value={value}
        onChange={(e, key, value) => {
          onChange(schema.key, null, value)
        }}>
        {schema.options.map(opt => {
          const label = opt && opt.label ? opt.label : opt
          const value = opt && opt.value ? opt.value : opt
          return <MenuItem key={value} value={value} primaryText={label} />
        })}
      </SelectField>*/}
      <Select
        className={schema.label}
        classNamePrefix="form-select"
        isSearchable={false}
        isMulti={true}
        options={options}
        onChange={(data) => {
          const values = data ? data.map((el) => el.value) : [];
          onChange(schema.key, null, values);
        }}
        value={values}
      />
    </span>
  );
};

const FormDropzone = ({ schema, value, onDrop }) => {
  const dropStyle = {
    border: "1px solid #cacaca",
    background: "#efefef",
    marginBottom: "50px",
    textAlign: "center",
    padding: "80px 10px",
    fontSize: "30px",
    cursor: "pointer",
  };
  const path = get(value, "path")
    ? `${process.env.REACT_APP_SERVER}${value.path}`
    : null;
  const preview = get(value, "preview") ? value.preview : null;
  const src = path || preview || null;
  let typeOf = get(value, "typeOf") || null;
  if (value && value.type) {
    typeOf = value.type.includes("video")
      ? "video"
      : value.type.includes("image")
      ? "image"
      : null;
  }
  return (
    <Dropzone onDrop={(files) => onDrop(schema.key, files)} style={dropStyle}>
      {src ? (
        <div>
          <p>{schema.label}</p>
          {typeOf === "image" ? (
            <img src={src} style={{ maxWidth: 300 }} />
          ) : typeOf === "video" ? (
            <div>
              <video width="300" autoPlay={false} controls>
                <source src={src} />
                Your browser does not support the video tag.
              </video>
              <div>{value.name}</div>
            </div>
          ) : (
            <div>Pas de preview disponible pour ce type de fichier</div>
          )}
        </div>
      ) : (
        <p>{schema.labelEmpty || schema.label}</p>
      )}
    </Dropzone>
  );
};

class CustomForm extends Component {
  constructor(props) {
    super(props);

    this.renderElement = this.renderElement.bind(this);
  }

  /* work with string or array prop (firstname, [firstname, lastname]) */
  getRawValue(data, prop) {
    let value = "";
    if (Array.isArray(prop)) {
      prop.forEach((p, index) => {
        if (index > 0) {
          value += " ";
        }
        value += _.get(data, p) || "";
      });
    } else {
      value = _.get(data, prop);
    }

    if (Array.isArray(value)) {
      value = value.join(", ");
    }
    return value;
  }

  renderElement(el) {
    const { data } = this.props;
    if (!data) return null;
    let link = handleJsonLink(data, el.link);
    let shouldShow = false;

    if (el.if) {
      const condition = !Array.isArray(el.if) ? [el.if] : el.if;
      const conditionKey = condition[0];
      const conditionValue = condition[1];
      if (conditionValue && _.get(data, conditionKey) === conditionValue) {
        shouldShow = true;
      } else if (!conditionValue && !!_.get(data, conditionKey)) {
        shouldShow = true;
      }
    } else {
      shouldShow = true;
    }
    if (!shouldShow) {
      return null;
    }

    switch (el.type) {
      case "raw":
        return (
          <span key={`raw-${el.key}`}>
            <label>{el.label}</label>
            <div>
              {!link ? this.getRawValue(data, el.key) : ""}
              {link ? (
                <NavLink to={`${link}`}>
                  {this.getRawValue(data, el.key)}
                </NavLink>
              ) : (
                ""
              )}
            </div>
          </span>
        );

      case "rawHtml":
        return (
          <span key={`raw-${el.key}`}>
            <label>{el.label}</label>
            <div
              dangerouslySetInnerHTML={{
                __html: this.getRawValue(data, el.key),
              }}
            ></div>
          </span>
        );

      case "rawDate":
        return (
          <span key={`rawDate-${el.key}`}>
            <label>{el.label}</label>
            <div>{moment(_.get(data, el.key)).format("DD/MM/YYYY HH:mm")}</div>
          </span>
        );

      case "input":
        return (
          <FormInput
            key={`input-${el.key}`}
            schema={el}
            value={_.get(data, el.key)}
            onChange={this.props.onChange}
          />
        );

      case "inputNumber":
        return (
          <FormInputNumber
            key={`inputNumber-${el.key}`}
            schema={el}
            value={_.get(data, el.key)}
            onChange={this.props.onChange}
          />
        );

      case "inputTags":
        return (
          <span key={`input-tags-${el.key}`}>
            <label>{el.label}</label>
            <TagsInput
              name={el.key}
              tags={_.get(data, el.key)}
              onChange={this.props.onChange}
            />
          </span>
        );

      case "inputUsers":
        return (
          <span key={`input-users-${el.key}`}>
            <label>{el.label}</label>
            <UsersInput
              name={el.key}
              tags={_.get(data, el.key)}
              onChange={this.props.onChange}
            />
          </span>
        );

      case "inputUser":
        return (
          <span key={`input-users-${el.key}`}>
            <label>{el.label}</label>
            <UserInput
              name={el.key}
              value={_.get(data, el.key)}
              onChange={this.props.onChange}
            />
          </span>
        );

      case "inputSelections":
        return (
          <span key={`input-selection-${el.key}`}>
            <label>{el.label}</label>
            <SelectionsInput
              name={el.key}
              selections={_.get(data, el.key)}
              onChange={this.props.onChange}
            />
          </span>
        );

      case "rawSelect":
        return (
          <FormSelect
            key={`rawSelect-${el.key}`}
            schema={el}
            value={_.get(data, el.key)}
            onChange={this.props.onChange}
          />
        );

      case "rawSelectMultiple":
        return (
          <FormSelectMultiple
            key={`rawSelect-${el.key}`}
            schema={el}
            value={_.get(data, el.key)}
            onChange={this.props.onChange}
          />
        );

      case "textarea":
        return (
          <FormTextarea
            key={`textarea-${el.key}`}
            schema={el}
            value={_.get(data, el.key)}
            onChange={this.props.onChange}
          />
        );

      case "checkbox":
        return (
          <FormCheckbox
            key={`checkbox-${el.key}`}
            schema={el}
            value={_.get(this.props, `data.${el.key}`)}
            onChange={this.props.onChange}
          />
        );

      case "datepicker":
        return (
          <FormDatePicker
            key={`datepicker-${el.key}`}
            schema={el}
            value={_.get(this.props, `data.${el.key}`)}
            onChange={this.props.onChange}
          />
        );

      case "timepicker":
        return (
          <FormTimePicker
            key={`timepicker-${el.key}`}
            schema={el}
            value={_.get(this.props, `data.${el.key}`)}
            onChange={this.props.onChange}
          />
        );

      case "dropzone":
        return (
          <FormDropzone
            key={`dropzone-${el.key}`}
            schema={el}
            value={_.get(this.props, `data.${el.key}`)}
            onDrop={this.props.onDrop}
          />
        );

      default:
        return null;
    }

    return null;
  }

  onSubmit = (e) => {
    const { data } = this.props;
    this.props.onSubmit(e, _.get(data, "_id"));
  };

  render() {
    const { schema, data, onSubmit, hideSubmitButton, children } = this.props;

    const label = _.get(data, "_id") ? "Sauvegarder" : "Créer";
    return (
      <form className="form">
        {schema.map((el, index) => {
          const zIndex = 100 - index;
          const style = { position: "relative", zIndex: zIndex };
          {
            /*const style = el.type === 'inputTags' ? {position: 'relative', zIndex: 100} : {position: 'relative', zIndex: zIndex}*/
          }
          return (
            <div key={`group-${index}`} className="form-group" style={style}>
              {this.renderElement(el)}
            </div>
          );
        })}
        {children}
        {!hideSubmitButton && (
          <div className="form-actions">
            <RaisedButton
              label={label}
              primary={true}
              onClick={this.onSubmit}
            />
          </div>
        )}
      </form>
    );
  }
}

CustomForm.propTypes = {
  schema: PropTypes.array.isRequired,
  data: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CustomForm;
