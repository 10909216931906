import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PropTypes from "prop-types";
import { fetch, onChange, onSubmit } from "./utils";

import * as snackbarActions from "actions/snackbar";

const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(snackbarActions, dispatch),
});

const initialState = () => {
  return {
    data: null,
  };
};

class PageDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = initialState();

    this.fetch = fetch.bind(this, props.model, props.populate);
    this.onChange = onChange.bind(this);
    this.onSubmit = onSubmit.bind(this, props.model);
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState(initialState());
      this.fetch();
    }
  }

  render() {
    const View = this.props.view;
    const { form, forms, container } = this.props;
    return (
      <View
        {...this.state}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        form={form}
        forms={forms}
        container={container}
        location={this.props.location}
      />
    );
  }
}

PageDetailContainer.propTypes = {
  model: PropTypes.string.isRequired,
  populate: PropTypes.any /* object or array */,
  view: PropTypes.func,
  /* container hook for not generic action, see utils.js */
  afterFetch: PropTypes.func,
  beforeChange: PropTypes.func,
  beforeSubmit: PropTypes.func,
};

export default connect(null, mapDispatchToProps, null, {
  pure: true,
  withRef: true,
})(PageDetailContainer);
