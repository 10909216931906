import React, { Component } from 'react'
import messages from 'constants/messages'
import get from 'lodash/get'
import moment from 'moment'

import columns from './columns.json'
import columnsTags from './columns-tags.json'
import filters from './filters.json'

import PageListContainer from 'components/PageListContainer'
import View from './component'

import * as snackbarActions from 'actions/snackbar'
import services from 'services'
import store from 'store'
import download from 'downloadjs'

class UsersContainer extends Component {
  constructor(props) {
    super(props)

    this.columnsTags = columnsTags.map(c => {
      if (c.prop === 'criterias') {
        c.onClick = this.toggleCriteriaForUser
      }
      return c
    })

    this.state = {
      model: 'users',
      populate: {
        path: 'sponsor',
        select: 'firstname lastname'
      },
      container: {
        onExport: this.onExport,
        onExportCsv: this.onExportCsv,
        showTags: false,
        onChangeShowTags: this.onChangeShowTags,
        criterias: [
          'A',
          'B',
          'C',
          'D',
          'E',
          'F',
          'G',
          'H',
          'I',
          'J',
          'K',
          'L',
          'M',
          'N',
          'O',
          'P',
          'Q',
          'R',
          'S',
          'T',
          'U',
          'V',
          'W',
          'X',
          'Y',
          'Z'
        ],
        criteriasSelected: [],
        filterCriteria: this.filterCriteria
      },
      columns,
      filters
    }
  }

  onExport = () => {
    const query = this.pageListContainer.buildQueryWithFilter()
    services.usersAll
      .find({
        query
      })
      .then(result => {
        let str = ''
        result.users.forEach(user => {
          str += user.email + '\n'
        })
        download(str, 'users.txt', 'text/plain')
      })
      .catch(err => {
        console.error(err)
        const message = get(messages, err.message) || err.message || 'Unknown error'
        store.dispatch(snackbarActions.open('error', message))
      })
  }

  onExportCsv = () => {
    const query = this.pageListContainer.buildQueryWithFilter()
    services.usersAll
      .find({
        query
      })
      .then(result => {
        let str = ''
        const fields = ['createdAt', 'email', 'lastname', 'firstname', 'phone.number', 'gender', 'nbPublicTalks', 'badges', 'typeOf']
        // for (const field of fields) {
        //   if (field !== 'createdAt') {
        //     str += ','
        //   }
        //   str += field
        // }
        // for (const criteria of this.state.container.criterias) {
        //   str += `, ${criteria}`
        // }
        // str += ',privateTags\n'

        str = "CREATION_DATE, EMAIL, NOM, PRENOM, PHONE, GENDER, NB_PUBLIC_TALKS, BADGES, TYPEOF, HAS_ACCOUNT\n"
        result.users.forEach(user => {
          for (const field of fields) {
            if (field !== 'createdAt') {
              str += ', '
            }
            const userField = get(user, field)
            let cleanField =
              userField && field !== 'email' && field !== 'linkedin.publicUrl' && field !== "firstname" && field !== "lastname"
                ? userField
                    .toString()
                    .trim()
                    .replace(/\W/g, ' ')
                : userField
            if (cleanField) {
              cleanField = cleanField.replace(new RegExp(',', 'g'), ' ').replace(new RegExp('\n', 'g'), '')
            }
            if (field === 'createdAt') {
              cleanField = moment(userField).format('DD/MM/YYYY')
            }
            if (field === 'typeOf') {
              cleanField = cleanField.split(' ').join('-')
            }
            str += cleanField ? cleanField : '0'
          }

          // for (const criteria of this.state.container.criterias) {
          //   str += `, ${(user.criterias && user.criterias.indexOf(criteria) !== -1 ? '1' : '0')}`
          // }
          // str += `,"${user.typeOf}"`
          str += ',true'
          str += '\n'
        })
        download(str, 'users.csv', 'text/plain')
      })
      .catch(err => {
        console.error(err)
        const message = get(messages, err.message) || err.message || 'Unknown error'
        store.dispatch(snackbarActions.open('error', message))
      })
  }

  onChangeShowTags = () => {
    this.setState({
      ...this.state,
      container: {
        ...this.state.container,
        showTags: !this.state.container.showTags
      },
      columns: !this.state.container.showTags ? this.columnsTags : columns
    })
  }

  filterCriteria = c => {
    const criteriasSelected = [].concat(this.state.container.criteriasSelected)
    const i = criteriasSelected.indexOf(c)
    if (i === -1) {
      criteriasSelected.push(c)
    } else {
      criteriasSelected.splice(i, 1)
    }

    this.setState(
      {
        ...this.state,
        container: {
          ...this.state.container,
          criteriasSelected
        }
      },
      () => this.pageListContainer.fetch(1)
    )

    this.pageListContainer.fetch()
  }

  toggleCriteriaForUser = (user, criteria) => {
    user.criterias = user.criterias || []
    const i = user.criterias.indexOf(criteria)
    if (i === -1) {
      user.criterias.push(criteria)
    } else {
      user.criterias.splice(i, 1)
    }

    let users = this.pageListContainer.state.data.map(u => {
      if (u._id === user._id) {
        u = user
      }
      return u
    })

    this.pageListContainer.setState({
      data: users
    })

    services.users
      .patch(user._id, {
        criterias: user.criterias
      })
      .catch(e => {
        console.error(e)
        const message = get(messages, e.message) || e.message || 'Unknown error'
        store.dispatch(snackbarActions.open('error', message))
      })
  }

  render() {
    return (
      <PageListContainer
        {...this.state}
        ref={ref => {
          this.pageListContainer = ref
        }}
        location={this.props.location}
        view={View}
      />
    )
  }
}

export default UsersContainer
