import React, { Component } from 'react'
import form from './form.json'
import { generateCatalog } from 'services'
import get from 'lodash/get'
import axios from 'axios'
import messages from 'constants/messages.json'
import store from 'store'

import PageDetailContainer from 'components/PageDetailContainer'
import View from './component'
import { books } from 'services'

class BooksDetailContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      model: 'books',
      form,
      container: {
        /*
         ** -1 : error
         ** 0 : pending
         ** 1 : success
         ** 2 : sleep
         */
        uploadStatus: 2,
        uploadErrorCode: null,
        uploadErrorMessage: null,
        onDrop: this.onDrop,
      },
    }
  }

  /* fetch result need to be modified specificly for users array */
  afterFetch(result) {
  }

  beforeSubmit(data) {
  }

  afterSubmit = (mode, result) => {
    if (mode === "create") {
      window.location = `/admin/books/${result._id}`
    }
  }

  uploadError = (e) => {
    console.error(e)
    const error = get(e, 'response.data') || e
    this.setState({
      ...this.state,
      container: {
        ...this.state.container,
        uploadStatus: -1,
        uploadErrorCode: get(error, 'data.code') || 'unknown',
        uploadErrorMessage: get(messages, error.message) || error.message || error,
      },
    })
  }

  uploadSuccess = () => {
    this.setState({
      ...this.state,
      container: {
        ...this.state.container,
        uploadStatus: 1,
        uploadErrorCode: null,
        uploadErrorMessage: null,
      },
    })
  }

  onDrop = (files) => {
    const stateData = this.pageDetailContainer.getWrappedInstance().state.data
    const bookId = stateData._id
    this.setState({
      ...this.state,
      container: {
        ...this.state.container,
        uploadStatus: 0,
        uploadErrorCode: null,
        uploadErrorMessage: null,
      },
    })
    const token = get(store.getState(), 'session.user.token')
    const file = files[0]

    let data = new FormData()
    data.append('image', file, file.name)
    // data.append('bookId', bookId)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        'request-from-backoffice': true,
      },
    }

    return axios
      .post(`${process.env.REACT_APP_SERVER}/upload/book`, data, config)
      .then((response) => {
        if (bookId) {
          this.updateBookCover(response.data)
        } else {
          this.addCoverToState(response.data)
        }
      })
      .catch(this.uploadError)
  }

  addCoverToState = (data) => {
    if (!data) {
      return this.uploadError({ message: 'Picture undefined' })
    }

    this.pageDetailContainer.getWrappedInstance().setState({
      ...this.pageDetailContainer.getWrappedInstance().state,
      data: {
        ...this.pageDetailContainer.getWrappedInstance().state.data,
        cover: {
          ...data,
          path: `${data.path}?${new Date().getTime()}`,
        },
      },
    })
    this.uploadSuccess()
  }

  updateBookCover = (data) => {
    if (!data) {
      return this.uploadError({ message: 'Picture undefined' })
    }
    const bookId = this.pageDetailContainer.getWrappedInstance().state.data._id

    books
      .patch(bookId, {
        cover: data,
      })
      .then((data) => {
        this.pageDetailContainer.getWrappedInstance().setState({
          ...this.pageDetailContainer.getWrappedInstance().state,
          data: {
            ...this.pageDetailContainer.getWrappedInstance().state.data,
            cover: {
              ...data.cover,
              path: `${data.cover.path}?${new Date().getTime()}`,
            },
          },
        })
        this.uploadSuccess()
      })
      .catch(this.uploadError)
  }
  

  render() {
    return (
      <PageDetailContainer
        {...this.state}
        ref={(ref) => {
          this.pageDetailContainer = ref
        }}
        afterFetch={this.afterFetch}
        beforeSubmit={this.beforeSubmit}
        afterSubmit={this.afterSubmit}
        location={this.props.location}
        match={this.props.match}
        view={View}
      />
    )
  }
}

export default BooksDetailContainer
