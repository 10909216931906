import React, { Component } from "react";
import form from "./form.json";
import get from "lodash/get";
import axios from "axios";
import messages from "constants/messages.json";
import store from "store";
import services from "services";

import PageDetailContainer from "components/PageDetailContainer";
import View from "./component";

import { users } from "services";

class UsersDetailContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: "users",
      populate: {
        path: "sponsor",
        select: "firstname lastname",
      },
      container: {
        /*
         ** -1 : error
         ** 0 : pending
         ** 1 : success
         ** 2 : sleep
         */
        uploadStatus: 2,
        uploadErrorCode: null,
        uploadErrorMessage: null,
        onDrop: this.onDrop,
      },
      form,
    };

    this.afterFetch = this.afterFetch.bind(this);
    this.beforeSubmit = this.beforeSubmit.bind(this);
    this.fetchTalks();
  }

  fetchTalks = () => {
    services["conferences"]
      .find({
        query: { select: "_id title", author: this.props.match.params.id },
      })
      .then((result) => {
        console.log("result : ", result);
        this.setState({
          container: {
            ...this.state.container,
            talks: result.data,
          },
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  afterFetch(result) {
    // result.privateTags = (result.privateTags || []).map((tag, index) => {
    //   return { id: index, text: tag }
    // })
    if (result.sponsor) {
      result.sponsor = {
        id: result.sponsor._id,
        text: `${result.sponsor.firstname} ${result.sponsor.lastname}`,
      };
    }
  }

  beforeSubmit(data) {
    if (data.sponsor) {
      data.sponsor = data.sponsor.id;
    }
    // data.privateTags = data.privateTags.map((tag) => {
    //   return tag.text
    // })
  }

  uploadError = (e) => {
    console.error(e);
    const error = get(e, "response.data") || e;
    this.setState({
      ...this.state,
      container: {
        ...this.state.container,
        uploadStatus: -1,
        uploadErrorCode: get(error, "data.code") || "unknown",
        uploadErrorMessage:
          get(messages, error.message) || error.message || error,
      },
    });
  };

  uploadSuccess = () => {
    this.setState({
      ...this.state,
      container: {
        ...this.state.container,
        uploadStatus: 1,
        uploadErrorCode: null,
        uploadErrorMessage: null,
      },
    });
  };

  onDrop = (files) => {
    this.setState({
      ...this.state,
      container: {
        ...this.state.container,
        uploadStatus: 0,
        uploadErrorCode: null,
        uploadErrorMessage: null,
      },
    });
    const token = get(store.getState(), "session.user.token");
    const file = files[0];
    const userId = this.pageDetailContainer.getWrappedInstance().state.data._id;

    let data = new FormData();
    data.append("image", file, file.name);
    data.append("userId", userId);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        "request-from-backoffice": true,
      },
    };

    return axios
      .post(`${process.env.REACT_APP_SERVER}/upload/image`, data, config)
      .then((response) => {
        this.updateUserPicture(response.data);
      })
      .catch(this.uploadError);
  };

  updateUserPicture = (data) => {
    console.log("updateUserPicture : ", data);
    if (!data) {
      return this.uploadError({ message: "Picture undefined" });
    }
    const userId = this.pageDetailContainer.getWrappedInstance().state.data._id;

    users
      .patch(userId, {
        picture: data,
      })
      .then((data) => {
        console.log(this.pageDetailContainer.getWrappedInstance());
        this.pageDetailContainer.getWrappedInstance().setState({
          ...this.pageDetailContainer.getWrappedInstance().state,
          data: {
            ...this.pageDetailContainer.getWrappedInstance().state.data,
            picture: {
              ...data.picture,
              path: `${data.picture.path}?${new Date().getTime()}`,
            },
          },
        });
        this.uploadSuccess();
      })
      .catch(this.uploadError);
  };

  render() {
    return (
      <PageDetailContainer
        {...this.state}
        ref={(ref) => {
          this.pageDetailContainer = ref;
        }}
        afterFetch={this.afterFetch}
        beforeSubmit={this.beforeSubmit}
        location={this.props.location}
        match={this.props.match}
        view={View}
      />
    );
  }
}

export default UsersDetailContainer;
