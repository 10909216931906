import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { adminCount } from 'services'
import * as actions from 'actions/session'

import { Sidebar, MobileNavbar } from './component'

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      talks: -1,
      events: -1,
      users: -1,
      matchs: -1,
      pro: -1
    }

    adminCount.find({})
    .then(result => {
      this.setState(result)
    }).catch(e => {
      console.error(e)
      if (e && e.code === 401) {
        this.onLogout()
      } 
    })

    this.onLogout = this.onLogout.bind(this)
  }

  onLogout() {
    localStorage.removeItem('user')
    this.props.actions.setUser(null)
    window.location = '/admin/login'
  }

  render() {
    return (
      <div>
        <Sidebar {...this.state} onLogout={this.onLogout}/>
        <MobileNavbar {...this.state} onLogout={this.onLogout} />
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps, null, { pure: true })(Navbar)
