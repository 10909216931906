import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './style.css'
import 'assets/styles/flex.css'

import Snackbar from 'material-ui/Snackbar'
import Navbar from 'components/Navbar'

const mapStateToProps = state => ({
  snackbar: state.snackbar
})

class Layout extends Component {
  render() {
    let snackbarClassName = 'snackbar'
    if (this.props.snackbar.typeOf === 'error') {
      snackbarClassName += ' error'
    }
    return (
      <div className='layout'>
        <Navbar />
        <div className='container'>
        { this.props.title ? <div className='title-page'>{this.props.title}</div>: '' }
          {this.props.children}
        </div>
        <Snackbar
          className={snackbarClassName}
          open={this.props.snackbar.isOpen}
          message={this.props.snackbar.message}
          autoHideDuration={8000}
          onRequestClose={this.handleRequestClose}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, null, null, { pure: true })(Layout)
