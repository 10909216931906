import { onDelay } from "utils";
import _ from "lodash";
import services from "services";
import messages from "messages.json";

export const fetch = function (model, populate) {
  let id = this.props.match.params.id;

  if (!id) {
    this.setState({
      data: {},
    });
    return;
  }

  services[model]
    .get(id)
    .then((result) => {
      /* if the afterFetch function exists in the detail container (example: FeedbackDetail) */
      if (this.props.afterFetch) {
        this.props.afterFetch(result.data);
      }
      this.setState({
        data: {
          ...this.state.data,
          ...result.data,
        },
      });
    })
    .catch((e) => {
      console.error(e);
    });
};

export const onChange = function (key, e, value) {
  value = _.get(e, "target.value") || value || "";
  if (this.props.beforeChange) {
    const changed = this.props.beforeChange(key, value);
    if (changed) {
      value = changed;
    }
  }
  let data = _.set(this.state.data, key, value);
  this.setState({
    data,
  });
};

export const onSubmit = function (model, e) {
  e.preventDefault();
  let data = Object.assign({}, this.state.data);
  // let id = this.props.match.params.id;
  let id = data._id || this.props.match.params.id;

  if (this.props.beforeSubmit) {
    this.props.beforeSubmit(data);
  }

  console.log("ON SUBMIT : ", id, data);
  /* PATCH OR CREATE HERE */
  let promise;
  if (id) {
    promise = services[model].patch(id, data);
  } else {
    promise = services[model].create(data);
  }

  promise
    .then((result) => {
      this.props.snackbarActions.open("success", "Modifications sauvegardés");
      if (this.props.afterSubmit) {
        this.props.afterSubmit(id ? "patch" : "create", result);
      }
    })
    .catch((e) => {
      console.error(e);
      this.props.snackbarActions.open(
        "error",
        `Error : ${_.get(messages, e.message)}`
      );
    });
};
