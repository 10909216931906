import React, { Component } from 'react'
import _ from 'lodash'
import messages from 'constants/messages'
import './index.css'

import Layout from 'components/Layout'
import Form from 'components/Form'

class UsersCreateView extends Component {
  constructor (props) {
    super(props)
  }

  render() {
    const { form, data, onChange, onSubmit, errorMessage, errors } = this.props

    return (
      <Layout {...this.props} title='User Detail'>
        <div className='users-detail-page detail-page'>
          <Form schema={form} data={data} onChange={onChange} onSubmit={onSubmit}/>
          { (errorMessage || errors) ?
            <div className='error'>
            { errorMessage &&
              <div>Error : { _.get(messages, errorMessage) || errorMessage }</div>
            }
            { errors &&
              Object.keys(errors).map(field => {
                if (errors[field] && errors[field].message) {
                  return <div key={field}>{field} : {_.get(messages, errors[field].message) || errors[field].message}</div>
                }
                return ''
              })
            }
            </div>
          : '' }
        </div>
      </Layout>
    )
  }
}

export default UsersCreateView