import React, { Component } from 'react'
import './style.css'

// import {Select, Layout, Input, Radio } from 'element-react'
import FormInput from 'components/FormInput'
import TextField from 'material-ui/TextField'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton'

const Filters = ({ tree, q, sort, filter, onChange, onChangeFilter }) => {
  const searchTree = tree.search
  const sortTree = tree.sort
  const filterTree = tree.filter
  return (<div>
    <div className='filters md-layout'>
      <div className='md-flex-50 md-flex-xsmall-100'>
        {searchTree ?
          <div>
            <div className='label'>{searchTree.label}</div>
            <FormInput placeholder={searchTree.placeholder} onChange={onChange.bind(this, 'q')} value={q} />
          </div>
        : '' }

        {sortTree ?
          <div>
            <div className='label mgt10'>{sortTree.label}</div>
            <SelectField value={sort} onChange={onChange.bind(this, 'sort')}>
              {sortTree.options.map(option => {
                return (
                  <MenuItem key={option.value} value={option.value} primaryText={option.label} />
                )
              })}
            </SelectField>
          </div>
        : '' }
      </div>

      <div className='md-flex-50 md-flex-xsmall-100'>
        <RadioButtonGroup name="filter" defaultSelected={filter} onChange={onChangeFilter.bind(this, 'filter')}>
          {filterTree ?
            filterTree.map(f => {
              return (
                  <RadioButton
                    key={f.label}
                    value={f.value}
                    label={f.label}
                  />
              )
            })
          : ''}
        </RadioButtonGroup>
      </div>
    </div>
  </div>)
}

export default Filters