export const types = {
  SET_TOKEN: 'SET_TOKEN',
  SET_USER: 'SET_USER'
}

// export const setToken = ({ access_token, refresh_token }) => ({
//     type: types.SET_TOKEN,
//     access_token,
//     refresh_token
// })

export const setUser = user => ({
    type: types.SET_USER,
    payload: user
})