import React, { Component } from 'react'

import columns from './columns.json'
// import filters from './filters.json'

import PageListContainer from 'components/PageListContainer'
import View from './component'

class PromosContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      model: 'promos',
      populate: [{
        path: 'user', select: 'firstname lastname picture'
      }, {
        path: 'conference', select: 'title'
      }],
      columns,
      filters: {}
    }
  }

  render() {
    return (
      <PageListContainer {...this.state}
                      location={this.props.location}
                      view={View} />
    )
  }
}

export default PromosContainer

