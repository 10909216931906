import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import './index.css'
import * as actions from 'actions/session'
import { auth } from 'services'
import RaisedButton from 'material-ui/RaisedButton'

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
})

class ConnectAs extends Component {
  constructor(props) {
    super(props)
  }

  connectAs = () => {
    const { user } = this.props

    auth.create({
      email: user.email
    }).then(result => {
      localStorage.setItem('user', JSON.stringify(result))
      window.open(process.env.REACT_APP_WEB, '_blank')
      this.props.actions.setUser(result)
      console.log(result)
    }).catch(e => {
      console.error(e)
      
    })
  }

  render() {
    const { user } = this.props
    return (
      <div className='connect-as-user'>
        <a onClick={this.connectAs} ><RaisedButton className='raised' secondary={true}>Se connecter en tant que { _.get(user, 'firstname') } { _.get(user, 'lastname') }</RaisedButton></a>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps, null, { pure: true })(ConnectAs)
