import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Dropzone from "react-dropzone";
import _ from "lodash";
import "./style.css";
import ConnectAs from "components/ConnectAs";

import Layout from "components/Layout";
import Form from "components/Form";

class UserDetailView extends Component {
  constructor(props) {
    super(props);

    this.dropStyle = {
      border: "1px solid #cacaca",
      background: "#efefef",
      marginBottom: "50px",
      textAlign: "center",
      padding: "80px 10px",
      fontSize: "30px",
      cursor: "pointer",
    };
  }

  render() {
    const { container, form, data, onChange, onSubmit } = this.props;
    return (
      <Layout {...this.props} title="User Detail">
        <div className="users-detail-page detail-page">
          <div className="talks" style={{ marginBottom: 20 }}>
            <h3>Ses Talks :</h3>
            {container.talks &&
              container.talks.map((talk) => (
                <div style={{ marginBottom: 6 }} key={talk._id}>
                  <NavLink to={`/admin/talks/${talk._id}`}>
                    {talk.title}
                  </NavLink>
                </div>
              ))}
          </div>
          <div className="dropzone">
            <Dropzone onDrop={container.onDrop} style={this.dropStyle}>
              {container.uploadStatus === 2 && (
                <p>Uploader une photo (jpg, png etc...)</p>
              )}
              {container.uploadStatus === -1 && (
                <p>
                  Erreur : {container.uploadErrorMessage} -{" "}
                  {container.uploadErrorCode}
                </p>
              )}
              {container.uploadStatus === 0 && (
                <p>Upload en cours, patientez</p>
              )}
              {container.uploadStatus === 1 && <p>Upload réussi</p>}
            </Dropzone>
          </div>
          {data && <ConnectAs user={data} />}
          {_.get(data, "picture") ? (
            <div
              className="picture"
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_SERVER +
                  "/public" +
                  _.get(data, "picture.path")
                })`,
              }}
            />
          ) : (
            ""
          )}
          <Form
            schema={form}
            data={data}
            onChange={onChange}
            onSubmit={onSubmit}
          />
          {data && <ConnectAs user={data} />}
          <div className="talks">
            <h3>Ses Talks :</h3>
            {container.talks &&
              container.talks.map((talk) => (
                <div key={talk._id}>
                  <NavLink to={`/admin/talks/${talk._id}`}>
                    {talk.title}
                  </NavLink>
                </div>
              ))}
          </div>
        </div>
      </Layout>
    );
  }
}

export default UserDetailView;
