import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { messages } from 'services'
import './style.css'

class Chat extends Component {
  constructor(props) {
    super(props)

    this.state = {
      messages: [],
      total: -1
    }

    this.fetch.call(this)
  }

  fetch() {
    let id = this.props.matchId
    messages.find({
      query: {
        match: id,
        $sort: '-createdAt',
        $populate: [{
          path: 'author', select: 'firstname lastname picture'
        }]
      }
    }).then(result => {
      this.setState({
        messages: result.data,
        total: result.total
      })
    }).catch(error => {
      console.error(error)
    })
  }

  render() {
    const { messages, total } = this.state
    return (
      <div className='messages-list'>
        { total > -1 ? <h3>{ total } message(s)</h3> : 'Aucun message' }
        { messages.map((message, index) => {
          return (
            <div key={`msg-${index}`} className='message'>
              <strong>{message.author.firstname} {message.author.lastname} </strong>
              <span className='date'>, le {moment(message.createdAt).format('D MMM YYYY')} à {moment(message.createdAt).format('HH:mm')}</span>
              <div className='content'>{ message.content }</div>
            </div>
          )
        })}
      </div>
    )
  }
}

Chat.propTypes = {
  matchId: PropTypes.string
}

export default Chat
