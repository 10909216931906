import React, { Component } from 'react'
import form from './form.json'
import services from 'services'
import _ from 'lodash'

import PageDetailContainer from 'components/PageDetailContainer'
import View from './component'

class UsersCreateContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: null,
      errorMessage: null,
      data: {
        name: '',
        users: [],
        brief: '',
        showPrice: true,
      },
    }
  }

  onChange = (key, e, value) => {
    value = _.get(e, 'target.value') || value || ''
    let data = _.set(this.state.data, key, value)
    this.setState({
      data,
    })
  }

  onSubmit = () => {
    const data = Object.assign({}, this.state.data)
    data.users = data.users.map((tag) => {
      return tag.id
    })

    services['selections']
      .create(data)
      .then((result) => {
        if (result.data && result.data._id) {
          window.location = `/admin/selections/${result.data._id}`
        }
        this.setState({
          errors: null,
          errorMessage: null,
        })
      })
      .catch((err) => {
        console.error('Error : ', err)
        this.setState({
          errors: err.errors,
          errorMessage: err.message,
        })
      })
  }

  render() {
    return (
      <View
        errors={this.state.errors}
        errorMessage={this.state.errorMessage}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        form={form}
        data={this.state.data}
      />
    )
  }
}

export default UsersCreateContainer
