import { onDelay } from 'utils'
import services from 'services'

/* group of functions usable by every pages container (users, feedbacks etc ...) */

/* function to call on every constructor with "this" binded */
//export const init = function () {}

export const fetch = function(model, populate, page) {
  let service = services[model]
  console.log('FETCH : ', model, services)
  const limit = 30
  const skip = (page - 1) * limit
  let query = this.buildQueryWithFilter()
  // query = {
  //   ...query,
  //   $limit: limit,
  //   $skip: skip,
  //   $sort: this.state.sort,
  //   $populate: populate
  // }
  query = {
    ...query,
    limit,
    page,
    sort: this.state.sort
  }
  if (this.state.q && this.state.q.length > 0) {
    query.q = this.state.q
  }

  console.log('query : ', query)
  service
    .find({
      query
    })
    .then(result => {
      this.setState({
        data: result.data,
        total: result.total
      })
    })
    .catch(e => {
      console.error(e)
    })
}

export const onChangePage = function(page) {
  page = page.selected
  this.fetch(page + 1)
  this.setState({
    currentPage: page
  })
}

export const onChangeFilters = function(filterState) {
  const { q, sort, filter } = filterState
  this.setState(
    {
      q,
      sort,
      filter,
      currentPage: 0
    },
    () => {
      onDelay(500, 'search', () => {
        this.fetch(1)
      })
    }
  )
}

/* example of a filter option (filters.json) :
  ** {
    "label": "Qui ont des parrains",
    "value": "sponsor:true"
  }
  */
export const buildQueryWithFilter = function() {
  let query = {}

  if (this.props.container && this.props.container.criteriasSelected && this.props.container.criteriasSelected.length) {
    query.criterias = this.props.container.criteriasSelected.join(',')
  }

  if (this.state.filter === 0) {
    return query
  }
  let filter = this.state.filter.split(':')
  if (filter.length !== 2) {
    return query
  }
  query[filter[0]] = filter[1]

  return query
}
