import React, { Component } from 'react'

const TotalResult = ({label, total}) => {
  const style = {
    textAlign: 'center',
    fontSize: '1.2em',
    fontWeight: 400
  }
  return (<div style={style}>{total} {label} trouvé(s)</div>)
}

export default TotalResult