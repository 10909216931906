import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as snackbarActions from 'actions/snackbar'
import form from './form.json'
import formDesign from './formDesign.json'
import _ from 'lodash'
import get from 'lodash/get'
import services from 'services'
import moment from 'moment'
import axios from 'axios'
import store from 'store'
import { slugify, random } from 'utils/string'

import Component from './component'

const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(snackbarActions, dispatch),
})

const initialService = () => ({
  title: '',
  description: '',
  userSubscription: false,
  shareAndReduction: false,
  target: 'all',
  type: '',
  shortDescription: '',
  date: null,
  time: '00:00',
  price: '',
  wordpressUrl: '',
  promos: [],
})

class Services extends PureComponent {
  state = {
    errors: null,
    errorMessage: null,
    wpRequest: {
      loading: false,
      success: false,
      error: null,
    },
    services: [],
    data: initialService(),
  }
  async componentDidMount() {
    try {
      const result = await services['services'].find({
        query: { $populate: 'users.user shares.user', $limit: 20, $sort: '-createdAt' },
      })
      result.data = result.data.map(this.parseFetchedService)
      this.setState({ services: result.data })
    } catch (err) {
      console.error(err)
    }
  }

  parseFetchedService = (service) => {
    try {
      service.time = '00:00'
      if (service.date) {
        let date = moment(service.date).tz('Europe/Paris')
        let hour = date.get('hour')

        hour = hour >= 10 ? hour : `0${hour}`
        let minute = moment(service.date).get('minute')
        minute = minute >= 10 ? minute : `0${minute}`
        service.time = `${hour}:${minute}`
      }
      if (service.date) {
        service.dateEnabled = true
        service.date = new Date(service.date)
      }
      service.price = service.price || 0
      service.promos = service.promos || []
      service.users = service.users.reverse()
      service.shares = service.shares.reverse()
    } catch (err) {
      console.error(err)
    }
    return service
  }

  selectService = (service) => {
    this.setState({
      data: service || initialService(),
    })
  }

  onChange = (key, e, value) => {
    value = _.get(e, 'target.value') || value || ''
    let data = _.set({ ...this.state.data }, key, value)
    this.setState({
      data,
    })
  }

  addPromo = () => {
    const data = Object.assign({}, this.state.data)

    data.promos = data.promos || []
    data.promos.push({
      _id: random(25),
      code: '',
      price: '',
    })

    this.setState({ data })
  }

  onChangeState = (key, e, value) => {
    value = _.get(e, 'target.value') || value || ''
    let state = _.set({ ...this.state }, key, value)
    this.setState(state)
  }

  fetchWordpressArticle = async () => {
    const { wordpressUrl } = this.state.data
    const regex = /\/wp\/wp-admin\/post\.php\?post=([0-9]+)/gm
    const matchs = regex.exec(wordpressUrl)
    let articleId

    if (matchs && matchs[1]) {
      articleId = matchs[1]
    }

    if (!articleId) {
      this.setState({
        wpRequest: {
          message: "L'url semble invalide (ie: https://lespeakers.com/wp/wp-admin/post.php?post=323...)",
          error: true,
          success: false,
          loading: false,
        },
      })
      return
    }

    this.setState({
      wpRequest: {
        message: null,
        error: null,
        success: false,
        loading: true,
      },
    })

    try {
      const result = (await axios.get(`${process.env.REACT_APP_SERVER}/blog/services/${articleId}`)).data
      this.setState({
        data: {
          ...this.state.data,
          title: result.title.rendered,
          description: result.content.rendered,
        },
        wpRequest: {
          message: `Wordpress article "${result.title.rendered}" récupéré avec succès`,
          error: null,
          success: true,
          loading: false,
        },
      })
    } catch (err) {
      console.error(err)
      this.setState({
        wpRequest: {
          error: true,
          message: err.message,
          loading: false,
          success: false,
        },
      })
    }
  }

  onDrop = (key, files) => {
    const file = files[0]

    let data = _.set({ ...this.state.data }, key, file)
    this.setState({
      data,
    })
  }

  onSubmit = async () => {
    const service = { ...this.state.data }

    service.promos = (service.promos || []).filter((promo) => promo.code !== '' && promo.price !== '').map(p => ({ code: p.code, price: p.price }))

    const div = document.createElement('DIV')
    div.innerHTML = service.description
    service.rawDescription = (div.textContent || div.innerText || '').replace(/^\s*$(?:\r\n?|\n)/gm, '')

    div.innerHTML = service.title
    service.title = (div.textContent || div.innerText || '').replace(/^\s*$(?:\r\n?|\n)/gm, '')

    if (service.dateEnabled) {
      let date = moment(service.date).tz('Europe/Paris')
      if (service.date && service.time) {
        date.set('hour', service.time.substr(0, 2))
        date.set('minute', service.time.substr(3, 2))
      }
      service.date = date.toDate()
    } else {
      delete service.date
    }
    if (!service.isPublished || !service.slug) {
      service.slug = `${slugify(service.title)}`
    }

    try {
      // if (service.cover && service.cover instanceof File) {
      //   const file = await this.uploadMedia(service.cover)
      //   service.cover = file
      // }
      // if (service.media && service.media instanceof File) {
      //   const file = await this.uploadMedia(service.media)
      //   service.media = file
      // }
      console.log('SERVICE : ', service)
      let result
      if (service._id) {
        result = await services['services'].update(service._id, service, {
          query: { $populate: 'users.user' },
        })
      } else {
        result = await services['services'].create(service, {
          query: { $populate: 'users.user' },
        })
      }
      this.addOrUpdateService(this.parseFetchedService(result))
      this.props.snackbarActions.open('success', 'Service enregistré !')
    } catch (err) {
      console.error(err)
      this.setState({
        errors: err.errors,
        errorMessage: err.message,
      })
    }
  }

  onPublish = async () => {
    const ok = window.confirm(
      'En publiant ce service il sera visible par les utilisateurs et une notification email / push / sms sera envoyé automatiquement'
    )

    if (!ok) {
      return
    }

    const { data } = this.state

    if (!data._id) {
      this.props.snackbarActions.open('error', "Service n'est pas encore créé")
      return
    }

    try {
      const result = await services.servicesPublish(data._id).create({})
      this.props.snackbarActions.open('success', 'Service publié !')
      this.addOrUpdateService(this.parseFetchedService(result))
    } catch (err) {
      console.error(err)
    }
  }

  addOrUpdateService = (service) => {
    let { services } = this.state
    const index = services.findIndex((e) => e._id === service._id)
    if (index > -1) {
      services[index] = service
    } else {
      services = [service, ...services]
    }
    this.setState({ services, data: service, errors: null, errorMessage: null })
  }

  // uploadMedia = async media => {
  //   console.log('UPLOAD MEDIA : ', media)
  //   let data = new FormData()
  //   data.append('media', media)
  //   const token = get(store.getState(), 'session.user.token')
  //   const config = {
  //     headers: {
  //       'content-type': 'multipart/form-data',
  //       Authorization: `Bearer ${token}`
  //     }
  //   }
  //   try {
  //     const result = await axios.post(`${process.env.REACT_APP_SERVER}/upload/service`, data, config)
  //     return result.data
  //   } catch (err) {
  //     throw err
  //   }
  // }

  render() {
    return (
      <Component
        data={this.state.data}
        services={this.state.services}
        wpRequest={this.state.wpRequest}
        form={form}
        formDesign={formDesign}
        errors={this.state.errors}
        errorMessage={this.state.errorMessage}
        onChange={this.onChange}
        addPromo={this.addPromo}
        onPublish={this.onPublish}
        fetchWordpressArticle={this.fetchWordpressArticle}
        onDrop={this.onDrop}
        onSubmit={this.onSubmit}
        selectService={this.selectService}
      />
    )
  }
}

export default connect(null, mapDispatchToProps, null, { pure: true, withRef: true })(Services)
