import React, { Component } from "react";

import columns from "./columns.json";
// import filters from './filters.json'

import PageListContainer from "components/PageListContainer";
import View from "../SEOCommon/list";

class SEOContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: "tags",
      populate: [],
      columns,
      filters: {},
      container: {
        model: "tags",
      },
    };
  }

  render() {
    return (
      <PageListContainer
        {...this.state}
        location={this.props.location}
        sort="-count"
        view={View}
      />
    );
  }
}

export default SEOContainer;
