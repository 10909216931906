import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './style.css'

import FormInput from 'components/FormInput'
import RaisedButton from 'material-ui/RaisedButton'
import TextField from 'material-ui/TextField'

class Login extends Component {
  render () {
    return (
      <div className='login-page'>
        <form>
          <div className='form-group'>
            <FormInput onChange={this.props.onChange.bind(this, 'email')}
                       placeholder='email'
                       fullWidth
                       value={this.props.form.email} />
          </div>
          <div className='form-group'>
            <FormInput type='password'
                       placeholder='password'
                       fullWidth
                       onChange={this.props.onChange.bind(this, 'password')}
                       value={this.props.form.password} />
          </div>
          <div className='form-group'>
            <RaisedButton primary={true} label="Se connecter" onClick={this.props.onSubmit}></RaisedButton>
          </div>
          { this.props.error &&
            <div>{this.props.error}</div>
          }
        </form>
      </div>
      )
  }
}

Login.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired
}

export default Login