import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import RaisedButton from 'material-ui/RaisedButton'
import Layout from 'components/Layout'
import Table from 'components/Table'
import Pagination from 'components/Pagination'

class Books extends Component {
  render() {
    const { columns, data, total, currentPage, onChangePage, filters, onChangeFilters, location } = this.props
    return (
      <Layout {...this.props} title="Books">
        <div className="lead-page">
          <div className="block">
            <NavLink className="create-user" to="/admin/books/create">
              <RaisedButton className="raised" secondary={true}>
                Creer un livre
              </RaisedButton>
            </NavLink>
            <Pagination forcePage={currentPage} pageCount={total / 30} onPageChange={onChangePage} />
            <Table columns={columns} data={data} location={location} />
            <Pagination forcePage={currentPage} pageCount={total / 30} onPageChange={onChangePage} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default Books
