
import { types } from 'actions/snackbar'


const initialState = {
  isOpen: false,
  typeOf: '',
  message: ''
}

const snackbarReducer = (state = initialState, action) => {

  switch (action.type) {
    case types.OPEN_SNACKBAR:
      return {
          ...state,
          isOpen: true,
          typeOf: action.typeOf,
          message: action.payload
      }

    case types.CLOSE_SNACKBAR:
      return {
        ...state,
        isOpen: false
      }

    default:
      break
  }
  return state
}

export default snackbarReducer
