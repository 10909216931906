import React, { Component } from "react";
import Select from "react-select";
import find from "lodash/find";

import Layout from "components/Layout";
import Table from "components/Table";
import Pagination from "components/Pagination";
import Filters from "components/Filters";

const MODEL_OPTIONS = [
  { label: "Mots clés", value: "tags" },
  { label: "Speakers", value: "users" },
  // { label: "Talks", value: "talks" },
];

class SEO extends Component {
  onChangeModel = (model) => {
    switch (model.value) {
      case "tags":
        location.href = `/admin/seo/tags`;
        break;
      case "users":
        location.href = `/admin/seo/users`;
        break;
      case "talks":
        location.href = `/admin/seo/talks`;
        break;
    }
  };
  render() {
    const {
      columns,
      data,
      total,
      currentPage,
      onChangePage,
      filters,
      onChangeFilters,
      location,
      container,
    } = this.props;
    return (
      <Layout {...this.props} title="SEO">
        <div className="seo-page">
          <div className="block">
            <div style={{ marginBottom: 20 }}>
              <div className="label mgb10">Type de donnée</div>
              <Select
                className={`form-select-container`}
                classNamePrefix="form-select"
                isSearchable={false}
                options={MODEL_OPTIONS}
                onChange={this.onChangeModel}
                value={find(MODEL_OPTIONS, ["value", container.model])}
              />
            </div>
            {!!filters && Object.keys(filters).length > 0 && (
              <Filters tree={filters} onChange={onChangeFilters} />
            )}

            <Pagination
              forcePage={currentPage}
              pageCount={total / 30}
              onPageChange={onChangePage}
            />
            <Table columns={columns} data={data} location={location} />
            <Pagination
              forcePage={currentPage}
              pageCount={total / 30}
              onPageChange={onChangePage}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

export default SEO;
