import React, { Fragment, PureComponent } from 'react'
import Layout from 'components/Layout'
import Block from 'components/Block'
import _ from 'lodash'
import services from 'services'
import moment from 'moment'
import './style.css'
import messages from 'constants/messages'
import Form from 'components/Form'
import Checkbox from 'material-ui/Checkbox'
import Input from 'components/FormInput'
import RaisedButton from 'material-ui/RaisedButton'
import ErrorIcon from 'material-ui/svg-icons/alert/error'
import CheckIcon from 'material-ui/svg-icons/navigation/check'

import { timeAgo } from 'utils/datetime'

export default class Services extends PureComponent {
  render() {
    const {
      form,
      // formDesign,
      data,
      services,
      wpRequest,
      onPublish,
      onChange,
      onSubmit,
      onDrop,
      addPromo,
      fetchWordpressArticle,
      selectService,
      errorMessage,
      errors,
    } = this.props
    return (
      <Layout {...this.props} title="Services">
        <div className="service-page md-layout">
          {services && services.length > 0 && (
            <Block className="md-flex-100" title="Créez ou sélectionnez un service existant">
              <h2>Créer un nouveau service</h2>
              <form>
                <Checkbox
                  label="Créer un nouveau service"
                  checked={!data._id}
                  onCheck={(e, v) => {
                    selectService()
                  }}
                />
              </form>

              <h2>Selectionner un service déjà créé</h2>
              <form>
                {services.map((service) => (
                  <div key={service._id} className={'article ' + (service.isPublished ? 'published' : 'draft')}>
                    <Checkbox
                      label={
                        service.title +
                        (service.isPublished ? ' (publié)' : ' (brouillon)') +
                        (service.users && service.users.length > 0
                          ? ` - ${service.users.length} utilisateurs inscris`
                          : '')
                      }
                      checked={data._id === service._id}
                      onCheck={(e, v) => {
                        selectService(service)
                      }}
                    />
                  </div>
                ))}
              </form>
            </Block>
          )}

          <Block className="md-flex-100" title="Contenu du service">
            <form className="form">
              <div className="form-group">
                Veuillez rédigez votre contenu dans wordpress et copier le lien de l'article ci dessous. l'article peut
                rester en brouillon avec la categorie "service"
              </div>
              <div className="form-group">
                <label>Url d'article wordpress</label>
                <Input
                  value={data.wordpressUrl}
                  placeholder="ie: https://lespeakers.com/wp/wp-admin/post.php?post=323&action=edit"
                  fullWidth={true}
                  onChange={(e) => onChange('wordpressUrl', e)}
                />
                {!!wpRequest.message && <div style={{ marginTop: 4, fontWeight: 500 }}>{wpRequest.message}</div>}
              </div>
              <div className="form-actions">
                <RaisedButton
                  icon={wpRequest.error ? <ErrorIcon /> : wpRequest.success ? <CheckIcon /> : null}
                  label={'Récupérer mon article wordpress'}
                  labelPosition="before"
                  primary={true}
                  onClick={fetchWordpressArticle}
                />
              </div>
            </form>
            <div className="article-preview">
              <div
                className="article-title"
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}></div>
              <div
                className="article-description"
                dangerouslySetInnerHTML={{
                  __html: data.description,
                }}></div>
            </div>
          </Block>

          {/*<Block className="md-flex-100" title="Design sur la homepage">
            <Form schema={formDesign} data={data} onChange={onChange} hideSubmitButton={true} />
            {errors && (Object.keys(errors).includes('shortDescription') || Object.keys(errors).includes('icon')) ? (
              <div className="error">
                {errors &&
                  Object.keys(errors).map(field => {
                    if (errors[field] && errors[field].message) {
                      return (
                        <div key={field}>
                          {field} : {_.get(messages, errors[field].message) || errors[field].message}
                        </div>
                      )
                    }
                    return ''
                  })}
              </div>
            ) : (
              ''
            )}
          </Block>*/}

          <Block className="md-flex-100" title="Options du service">
            <Form schema={form} data={data} onChange={onChange} onDrop={onDrop} onSubmit={onSubmit}>
              {!!data.userSubscription && (
                <Fragment>
                  {data.promos &&
                    data.promos.map((promo, index) => (
                      <Fragment key={promo._id}>
                        <div className="form-group half">
                          <label>Code promo ({index + 1})</label>
                          <Input
                            value={_.get(data, `promos[${index}].code`)}
                            placeholder="Code promo"
                            fullWidth={false}
                            onChange={(e) => onChange(`promos[${index}].code`, e)}
                          />
                        </div>
                        <div className="form-group half">
                          <label>Prix</label>
                          <Input
                            value={_.get(data, `promos[${index}].price`)}
                            type="number"
                            placeholder="Prix"
                            fullWidth={false}
                            onChange={(e) => onChange(`promos[${index}].price`, e)}
                          />
                        </div>
                      </Fragment>
                    ))}

                  <div className="form-group">
                    <RaisedButton label={'Ajouter un code promo'} secondary={true} onClick={addPromo} />
                  </div>
                  {/*<div className="form-group half">
                    <label>Code promo ({data.promos.length + 1})</label>
                    <Input
                      value={_.get(data, `promos[${data.promos.length}].code`)}
                      placeholder="Code promo"
                      fullWidth={false}
                      onChange={e => onChange(`promos[${data.promos.length}].code`, e)}
                    />
                  </div>
                  <div className="form-group half">
                    <label>Prix</label>
                    <Input
                      value={_.get(data, `promos[${data.promos.length}].price`)}
                      placeholder="Prix"
                      fullWidth={false}
                      onChange={e => onChange(`promos[${data.promos.length}].price`, e)}
                    />
                  </div>*/}
                </Fragment>
              )}
            </Form>
            {errorMessage || errors ? (
              <div className="error">
                {errorMessage && <div>Error : {_.get(messages, errorMessage) || errorMessage}</div>}
                {errors &&
                  Object.keys(errors).map((field) => {
                    if (errors[field] && errors[field].message) {
                      return (
                        <div key={field}>
                          {field} : {_.get(messages, errors[field].message) || errors[field].message}
                        </div>
                      )
                    }
                    return ''
                  })}
              </div>
            ) : (
              ''
            )}
          </Block>

          {data._id && (
            <Block className="md-flex-100" title="Publication">
              <a
                target="_blank"
                style={{ marginBottom: 8, display: 'block' }}
                href={`${process.env.REACT_APP_WEB}/service/${data.slug}`}>
                {process.env.REACT_APP_WEB}/service/{data.slug}
              </a>
              <div className="form-actions">
                <a
                  style={{ marginRight: 10 }}
                  target="_blank"
                  href={`${process.env.REACT_APP_WEB}/service/${data.slug}`}>
                  <RaisedButton label={'Aperçu'} secondary={true} />
                </a>
                <RaisedButton
                  label={data.isPublished ? 'Publié' : 'Publier'}
                  disabled={!!data.isPublished}
                  primary={true}
                  onClick={onPublish}
                />
              </div>
            </Block>
          )}

          {data.userSubscription && data.users && data.users.length > 0 && (
            <Block className="md-flex-100" title="Utilisateurs inscris">
              {data.users.map((registration) => {
                const user = registration.user
                return (
                  <div key={registration._id}>
                    <span style={{ fontWeight: 600 }}>
                      {user.firstname} {user.lastname}
                    </span>{' '}
                    - {user.email} <span style={{ fontWeight: 600 }}>{user.phone}</span> ({timeAgo(registration.date)})
                  </div>
                )
              })}
            </Block>
          )}

          {data.shares && data.shares.length > 0 && (
            <Block className="md-flex-100" title="Partages">
              {data.shares.map((share) => {
                const network = share.network
                const user = share.user
                return (
                  <div key={share._id}>
                    <span style={{ fontWeight: 600 }}>
                      {network.toUpperCase()} - {user.firstname} {user.lastname}
                    </span>{' '}
                    - {user.email} <span style={{ fontWeight: 600 }}>{user.phone}</span> ({timeAgo(share.date)})
                  </div>
                )
              })}
            </Block>
          )}
        </div>
      </Layout>
    )
  }
}
