import store from 'store'

export const types = {
  OPEN_SNACKBAR: 'OPEN_SNACKBAR',
  CLOSE_SNACKBAR: 'CLOSE_SNACKBAR'
}

export const open = (typeOf, message) => {
  setTimeout(() => {
    store.dispatch(close())
  }, 8000)
  return {
    type: types.OPEN_SNACKBAR,
    typeOf,
    payload: message
  }
}

export const close = () => ({
    type: types.CLOSE_SNACKBAR
})