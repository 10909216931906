import React, { Fragment, PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import services from 'services'
import moment from 'moment'

import './index.scss'

import Layout from 'components/Layout'

class Payments extends PureComponent {
  state = {
    loaded: false,
    services: [],
    payments: [],
    active: null,
    hideEmails: false,
    show: 'lespeakers',
  }
  componentDidMount() {
    this.fetch()
  }

  activate = (id) => {
    if (this.state.active === id) {
      id = null
    }
    this.setState({
      active: id,
    })
  }

  showCustomer = () => {
    this.setState({ show: 'customer' })
  }

  showLespeakers = () => {
    this.setState({ show: 'lespeakers' })
  }

  toggleHideEmails = (e) => {
    e.stopPropagation()
    this.setState({
      hideEmails: !this.state.hideEmails,
    })
  }

  fetch = async () => {
    try {
      const result = await services.services.find({
        query: { $sort: '-createdAt', $limit: 20, $populate: 'users.user' },
      })
      const bundles = result.data.filter((s) => s.price && s.price > 0)
      const payments = await services.servicesPayments.find({
        query: {
          ids: bundles.map((s) => s._id).join(','),
        },
      })
      this.setState({ loaded: true, services: bundles, payments })
    } catch (err) {
      console.error(err)
    }
  }
  render() {
    const { loaded, services, payments, active, hideEmails, show } = this.state

    if (!loaded) {
      return (
        <Layout {...this.props} title="Payments">
          <div className="payments-page">Chargement en cours</div>
        </Layout>
      )
    }

    return (
      <Layout {...this.props} title="Payments">
        <div className="payments-page">
          <div className="services">
            {/*<div className="switcher">
              <button onClick={this.showLespeakers} className={'lsp' + (show === 'lespeakers' ? ' active' : '')}>
                Afficher le résultat lespeakers
              </button>
              <button onClick={this.showCustomer} className={'customer' + (show === 'customer' ? ' active' : '')}>
                Afficher le résultat client
              </button>
            </div>*/}
            {services.map((s) => {
              const fee_percent = (s.fee_percent || 0) / 100
              const payment = payments[s._id]

              return (
                <div
                  className={'service-line' + (active === s._id ? ' active' : '')}
                  onClick={() => this.activate(s._id)}
                  key={s._id}>
                  <div className="service-informations" key={s._id}>
                    <div className="title">{s.title}</div>
                    <div className="price">{(s.price || 0).toFixed(2)} €</div>
                  </div>
                  {!!payment && (
                    <Fragment>
                      <div className="service-payment">
                        <div>Paiements : {payment.totalPayments}</div>
                        <div>Total brut : {(payment.totalAmount / 100).toFixed(2)} €</div>
                        <div>Frais stripe : {(payment.totalFee / 100).toFixed(2)} €</div>
                        <div>Total net : {(payment.totalNet / 100).toFixed(2)} €</div>
                        <div>Commission Lespeakers : {fee_percent * 100} %</div>
                        <div>
                          Total client : {((payment.totalNet - fee_percent * payment.totalNet) / 100).toFixed(2)} €
                        </div>
                      </div>
                      {active === s._id && (
                        <div className="service-payment-detail">
                          <div className="hide-email">
                            <button onClick={this.toggleHideEmails}>
                              {hideEmails ? 'Afficher les emails' : 'Masquer les emails'}
                            </button>
                          </div>
                          {payment.payments.map((p) => (
                            <div className="payment-user" key={p._id}>
                              <div>
                                User : <span>{hideEmails ? '-' : p.user_email}</span>
                              </div>
                              <div>Date : {moment(p.createdAt).format('DD/MM/YYYY HH:mm:ss')}</div>
                              <div>Brut : {(p.amount / 100).toFixed(2)} €</div>
                              <div>Frais stripe : {(p.fee / 100).toFixed(2)} €</div>
                              <div>Net : {(p.net / 100).toFixed(2)} €</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </Fragment>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Payments
