import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { WithContext as ReactTags } from 'react-tag-input'
import './style.css'
import find from 'lodash/find'

import { onDelay } from 'utils'
import { selections } from 'services'

class TagsInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      suggestions: []
    }
    this.handleDelete = this.handleDelete.bind(this)
    this.handleAddition = this.handleAddition.bind(this)

    this.fetch()
  }

  fetch = () => {
    onDelay(200, 'searchSelections', () => {
      selections.find({
         query: {
            $limit: 50,
            $sort: '-createdAt'
          }
      }).then(result => {
        this.setState({
          suggestions: result.data.map((sugg, index) => {
            return { text: sugg.name.fr, id: sugg._id }
          })
        })
      }).catch(e => {
        console.error(e)
      })
    })
  }

  handleDelete(i) {
    if (!this.props.onChange) {
      return
    }
    let selections = this.props.selections
    selections.splice(i, 1)
    this.props.onChange(this.props.name, null, selections)
  }

  handleAddition(tag) {
    if (!this.props.onChange) {
      return
    }
    let selections = this.props.selections || []
    const selection = find(this.state.suggestions, ['text', tag])

    selections.push(selection)
    this.props.onChange(this.props.name, null, selections)
  }

  render () {
    const { suggestions } = this.state

    const tags = suggestions.map((sugg, index) => {
      return sugg.text
    })
    const selections = this.props.selections || []
    return (
      <ReactTags tags={selections}
                  placeholder='Ajouter une nouvelle selection'
                  suggestions={tags}
                  handleDelete={this.handleDelete}
                  handleAddition={this.handleAddition}
                  />
    )
  }
}

TagsInput.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  tags: PropTypes.array
}

export default TagsInput