import React, { Component } from 'react'

import Layout from 'components/Layout'
import Table from 'components/Table'
import Pagination from 'components/Pagination'

class Matchs extends Component {
  render() {
    const {
      columns,
      data,
      total,
      currentPage,
      onChangePage,
      filters,
      onChangeFilters,
      location
    } = this.props
    return (
      <Layout {...this.props} title='Liste des matchs'>
        <div className='matchs-page'>
          <div className='block'>
            <Pagination forcePage={currentPage}
                        pageCount={total / 30}
                        onPageChange={onChangePage}/>
            <Table columns={columns}
                   data={data} 
                   location={location}
                   />
            <Pagination forcePage={currentPage}
                        pageCount={total / 30}
                        onPageChange={onChangePage}/>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Matchs