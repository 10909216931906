import React, { Component } from 'react'
import { Provider } from 'react-redux'
import Router from 'modules/Router'
import store from 'store'
import 'services'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import {
  tealA700
} from 'material-ui/styles/colors'

import './style.css'
import 'assets/fonts/font-awesome/css/fontawesome-all.css'

const muiTheme = getMuiTheme({
  palette: {
     primary1Color: tealA700,
  }
})

export default class App extends Component {
  render() {
    return (
      <div className='app'>
        <MuiThemeProvider muiTheme={muiTheme}>
          <Provider store={store}>
            <Router />
          </Provider>
        </MuiThemeProvider>
      </div>
      )
  }
}