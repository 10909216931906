import React, { Component } from 'react'
import PropTypes from 'prop-types'

import View from './component'

class Filters extends Component {
  constructor (props) {
    super (props)

    this.state = {
      q: '',
      sort: '-createdAt',
      filter: this.props.tree.filter[0].value
    }

    this.onChange = this.onChange.bind(this)
    this.onChangeFilter = this.onChangeFilter.bind(this)
    this.onChangeCallback = this.onChangeCallback.bind(this)
  }

  onChangeCallback () {
    if (this.props.onChange) {
      this.props.onChange(this.state)
    }
  }

  onChange (key, e, i, value) {
    value = e.target.value || value || ''
    this.setState({
      [key]: value
    }, this.onChangeCallback)
  }

  onChangeFilter (key, e, value) {
    this.setState({
      [key]: value
    }, this.onChangeCallback)
  }

  render () {
    const { tree } = this.props
    return (
      <View {...this.state} tree={tree} 
                            onChange={this.onChange}
                            onChangeFilter={this.onChangeFilter}
                            />
    )
  }
}

Filters.propTypes = {
  tree: PropTypes.object.isRequired,
  onChange: PropTypes.func
}

export default Filters