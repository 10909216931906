import React, { Component } from "react";
import { connect } from "react-redux";
import store from "store.js";
import _ from "lodash";

import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import Login from "modules/Login";
import Home from "modules/Home";
import AdsManager from "modules/AdsManager";
import SEOTags from "modules/SEOTags";
import SEOTagDetail from "modules/SEOTagDetail";
import SEOUsers from "modules/SEOUsers";
import SEOUserDetail from "modules/SEOUserDetail";
// import Statistics from 'modules/Statistics'
// import SearchHistory from 'modules/SearchHistory'
// import Tracking from 'modules/Tracking'
import Users from "modules/Users";
import Pros from "modules/Pros";
import UsersDetail from "modules/UsersDetail";
import UsersCreate from "modules/UsersCreate";
import Events from "modules/Events";
import EventsDetail from "modules/EventsDetail";
import Feedbacks from "modules/Feedbacks";
import FeedbacksDetail from "modules/FeedbacksDetail";
import Matchs from "modules/Matchs";
import MatchsDetail from "modules/MatchsDetail";
import Promos from "modules/Promos";
import Leads from "modules/Leads";
import LeadsDetail from "modules/LeadsDetail";
import Selections from "modules/Selections";
import SelectionsDetail from "modules/SelectionsDetail";
import SelectionsCreate from "modules/SelectionsCreate";
import Books from "modules/Books";
import BooksDetail from "modules/BooksDetail";
import Notifications from "modules/Notifications";
import Services from "modules/Services";
import Forbidden from "modules/Forbidden";
import Payments from "modules/Payments";

const mapStateToProps = (state) => ({
  session: state.session,
});

const PrivateRoute = ({ component: Component, ...rest }) => {
  let user = _.get(store.getState(), "session.user");

  if (user && user.role !== "admin" && user.role !== "superadmin") {
    // localStorage.removeItem('user')
    window.location = "/admin/forbidden";
    return;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/admin/login",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

const PublicOnlyRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return !store.getState().session.user ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/admin/users",
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

const GoStatistics = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      return (
        <Redirect
          to={{
            pathname: "/admin/statistics",
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

const Routes = () => (
  <div>
    <Switch>
      <PrivateRoute exact path="/" component={Users} />

      {/*<GoStatistics exact path="/admin" component={Statistics} />*/}
      {/*<PrivateRoute exact path="/admin/statistics" component={Statistics} />*/}
      {/*<PrivateRoute exact path="/admin/search" component={SearchHistory} />*/}
      {/*<PrivateRoute exact path="/admin/tracking" component={Tracking} />*/}
      <PrivateRoute exact path="/admin/adsmanager" component={AdsManager} />
      <PrivateRoute exact path="/admin/seo/tags" component={SEOTags} />
      <PrivateRoute exact path="/admin/seo/tags/:id" component={SEOTagDetail} />

      <PrivateRoute exact path="/admin/seo/users" component={SEOUsers} />
      <PrivateRoute
        exact
        path="/admin/seo/users/:id"
        component={SEOUserDetail}
      />
      <PrivateRoute
        exact
        path="/admin/notifications"
        component={Notifications}
      />
      <PrivateRoute exact path="/admin/services" component={Services} />
      <PrivateRoute exact path="/admin/leads" component={Leads} />
      <PrivateRoute exact path="/admin/leads/:id" component={LeadsDetail} />
      <PrivateRoute exact path="/admin/payments" component={Payments} />

      <PrivateRoute exact path="/admin/users/create" component={UsersCreate} />
      <PrivateRoute exact path="/admin/users" component={Users} />
      <PrivateRoute exact path="/admin/pros" component={Pros} />
      <PrivateRoute exact path="/admin/users/:id" component={UsersDetail} />

      <PrivateRoute exact path="/admin/events" component={Events} />
      <PrivateRoute exact path="/admin/events/:id" component={EventsDetail} />

      <PrivateRoute exact path="/admin/talks" component={Feedbacks} />
      <PrivateRoute exact path="/admin/talks/:id" component={FeedbacksDetail} />

      <PrivateRoute exact path="/admin/matchs" component={Matchs} />
      <PrivateRoute exact path="/admin/matchs/:id" component={MatchsDetail} />

      <PrivateRoute exact path="/admin/promos" component={Promos} />
      <PrivateRoute exact path="/admin/selections" component={Selections} />
      <PrivateRoute
        exact
        path="/admin/selections/create"
        component={SelectionsCreate}
      />
      <PrivateRoute
        exact
        path="/admin/selections/:id"
        component={SelectionsDetail}
      />

      <PrivateRoute exact path="/admin/books" component={Books} />
      <PrivateRoute exact path="/admin/books/create" component={BooksDetail} />
      <PrivateRoute exact path="/admin/books/:id" component={BooksDetail} />

      <PublicOnlyRoute path="/admin/login" component={Login} />
      <Route path="/admin/forbidden" component={Forbidden} />
      <Route component={Forbidden} />
    </Switch>
  </div>
);

class Routing extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    );
  }
}

export default connect(mapStateToProps, null, null, { pure: true })(Routing);
