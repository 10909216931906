import React, { Component } from 'react'

import store from 'store.js'

import Layout from 'components/Layout'

class Home extends Component {
  render () {
    return (
      <Layout {...this.props}>
        <div className='homePage'>
          HOMEPAGE {store.getState().session.user.firstname} {store.getState().session.user.lastname}
        </div>
      </Layout>
      )
  }
}

export default Home