import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'

import RaisedButton from 'material-ui/RaisedButton'
import Layout from 'components/Layout'
import Form from 'components/Form'
import ConnectAs from 'components/ConnectAs'

class SelectionDetail extends Component {
  render() {
    const { container, form, data, onChange, onSubmit } = this.props
    return (
      <Layout {...this.props} title="Selection">
        <div className="selections-detail-page detail-page">
          <Form schema={form} data={data} onChange={onChange} onSubmit={onSubmit} />
          <RaisedButton className="export raised" secondary={true} onClick={container.onDownloadPdf}>
            {container.loadingDownload ? 'Telechargement en cours' : 'Telecharger PDF'}
          </RaisedButton>
        </div>
      </Layout>
    )
  }
}

export default SelectionDetail
