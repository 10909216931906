import React from 'react'
import ReactDOM from 'react-dom'
import App from './modules/App'
import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/fr'
// import registerServiceWorker from './registerServiceWorker'

ReactDOM.render(<App />, document.getElementById('root'))
// registerServiceWorker()

moment.locale('fr')
