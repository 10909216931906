import React, { Component } from "react";
import form_raw from "./form_raw.json";
import form_fr from "./form_fr.json";
import form_en from "./form_en.json";

import PageDetailContainer from "components/PageDetailContainer";
import View from "../SEOCommon/detail";

class SEOUserDetailContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: "users",
      forms: {
        raw: form_raw,
        fr: form_fr,
        en: form_en,
      },
    };
    this.beforeChange = this.beforeChange.bind(this);
  }

  beforeChange(key, value) {
    if (key.includes("seo_title") && value.length >= 100) {
      return value.slice(0, 100);
    }
    if (key.includes("seo_description") && value.length >= 200) {
      return value.slice(0, 200);
    }

    return value;
  }

  render() {
    return (
      <PageDetailContainer
        {...this.state}
        beforeChange={this.beforeChange}
        location={this.props.location}
        match={this.props.match}
        view={View}
        container={{
          title: "SEO USER",
          FRONTEND_URLS: {
            FR: `${process.env.REACT_APP_WEB}/conferencier/[slug]`,
            EN: `${process.env.REACT_APP_WEB}/en/speaker/[slug]`,
          },
          BACKOFFICE_URLS: {
            FR: `${process.env.REACT_APP_BACKOFFICE}/admin/users/[id]`,
            EN: `${process.env.REACT_APP_BACKOFFICE}/admin/users/[id]`,
          },
        }}
      />
    );
  }
}

export default SEOUserDetailContainer;
