import React, { Component } from 'react'
import './style.css'

import RaisedButton from 'material-ui/RaisedButton'

import Layout from 'components/Layout'
import Table from 'components/Table'
import Pagination from 'components/Pagination'
import Filters from 'components/Filters'
import TotalResult from 'components/TotalResult'
import download from 'downloadjs'

class Pros extends Component {
  onExport () {
    const { data } = this.props
    let str = ''
    data.forEach(user => {
      str += user.email + '\n'
    })
    download(str, 'users.txt', 'text/plain')
  }

  render() {
    const {
      columns,
      data,
      total,
      currentPage,
      onChangePage,
      filters,
      onChangeFilters,
      location
    } = this.props

    console.log('::', data)
    return (
      <Layout {...this.props} title='Liste des utilisateurs'>
        <div className='users-page'>
          <div className='block'>
            <Filters tree={filters} onChange={onChangeFilters} />
            <Pagination forcePage={currentPage}
                        pageCount={total / 30}
                        onPageChange={onChangePage}/>
            <TotalResult label='utilisateurs' total={total}/>
            <RaisedButton className='raised' secondary={true} onClick={this.onExport.bind(this)}>export</RaisedButton>
            <Table columns={columns}
                   data={data} 
                   location={location}
                   />
            <Pagination forcePage={currentPage}
                        pageCount={total / 30}
                        onPageChange={onChangePage}/>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Pros