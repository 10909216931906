import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { WithContext as ReactTags } from 'react-tag-input'
import './style.css'

import { onDelay } from 'utils'
import { tags } from 'services'


class TagsInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
        // tags: [{ id: 1, text: "Thailand" }, { id: 2, text: "India" }],
        suggestions: []
    }
    this.handleDelete = this.handleDelete.bind(this)
    this.handleAddition = this.handleAddition.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.fetch = this.fetch.bind(this)
  }

  fetch (q) {
    onDelay(200, 'searchTags', () => {
        tags.find({
          query: {
            name: q || '',
            limit: 6
          }
        }).then(result => {
          console.log(result)
          this.setState({
            suggestions: result.data.map(sugg => { return sugg.name })
          })
        }).catch(e => {
          console.error(e)
        })
      })
  }

  handleInputChange(v) {
    this.fetch(v)
  }

  handleDelete(i) {
    if (!this.props.onChange) {
      return
    }
    let tags = this.props.tags
    tags.splice(i, 1)
    this.props.onChange(this.props.name, null, tags)
  }

  handleAddition(tag) {
    if (!this.props.onChange) {
      return
    }
    let tags = this.props.tags
    tags.push({
      id: tags.length + 1,
      text: tag
    })
    this.props.onChange(this.props.name, null, tags)
  }

  render () {
    const { suggestions } = this.state
    const tags = this.props.tags

    return (
      <ReactTags tags={tags}
                  placeholder='Ajouter un nouveau tag'
                  suggestions={suggestions}
                  handleInputChange={this.handleInputChange}
                  handleDelete={this.handleDelete}
                  handleAddition={this.handleAddition}
                  />
    )
  }
}

TagsInput.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  tags: PropTypes.array
}

export default TagsInput