import React, { Component } from 'react'
import form from './form.json'
import services from 'services'
import _ from 'lodash'

import PageDetailContainer from 'components/PageDetailContainer'
import View from './component'

class UsersCreateContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: null,
      errorMessage: null,
      data: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        phone: '',
        speaker: true,
        role: 'user',
        activated: false,
        pro: {
          siret: '',
          address: '',
          price: {
            conference: '',
            interview: '',
            roundtable: ''
          }
        }
      }
    }
  }

  onChange = (key, e, value) => {
    value = _.get(e, 'target.value') || value || ''
    let data = _.set(this.state.data, key, value)
    this.setState({
      data
    })
  }

  onSubmit = () => {
    const { data } = this.state

    data.typeOf = []
    if (data.speaker) {
      data.typeOf.push('speaker')
    }
    if (data.organizer) {
      data.typeOf.push('organizer')
    }

    services['users'].create(data)
    .then(result => {
      console.log('result : ', result)
      window.location = `/admin/users/${result._id}`
      this.setState({
        errors: null,
        errorMessage: null
      })
    }).catch(err => {
      console.error('Error : ', err)
      this.setState({
        errors: err.errors,
        errorMessage: err.message
      })
    })
  }

  render() {
    return (
      <View
        errors={this.state.errors}
        errorMessage={this.state.errorMessage}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
        form={form}
        data={this.state.data}
      />
      )
  }
}

export default UsersCreateContainer
