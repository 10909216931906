import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import _ from 'lodash'
import './index.css'

import Layout from 'components/Layout'

const mapStateToProps = state => ({
  session: state.session
})

class AdsManager extends Component {
  render() {
    const user = _.get(this.props, 'session.user')
    return (
      <Layout {...this.props}>
      <GoogleOAuthProvider clientId="531270620814-s8mb3s2tbr3egt6m18v4as93rirkbbqr.apps.googleusercontent.com">
        <div className='adsManagerPage'>
          <div>ADS MANAGER scopex</div>
          <GoogleLogin
            ux_mode='redirect'
            onSuccess={credentialResponse => {
              console.log(credentialResponse);
            }}
            onError={() => {
              console.log('Login Failed');
            }}
            scope='https://www.googleapis.com/auth/adwords'
            useOneTap
          />
        </div>
      </GoogleOAuthProvider>
      </Layout>
    )
  }
}

export default connect(mapStateToProps, null, null, { pure: true })(AdsManager)
