import feathers from "feathers/client";
import rest from "feathers-rest/client";
import hooks from "feathers-hooks";
import axios from "axios";
import store from "store";
import _ from "lodash";
import { setUser } from "actions/session";
import api from "api";

const app = feathers()
  .configure(hooks())
  .configure(
    rest(
      process.env.REACT_APP_SERVER + "/" + process.env.REACT_APP_SERVER_VERSION
    ).axios(axios)
  );

export const selections = app.service("selections");
export const users = app.service("users");
export const usersPublic = app.service("users/slug");
export const usersAll = app.service("users/export");
export const conferences = app.service("talks");
export const matchs = app.service("matchs");
export const promos = app.service("promos");
export const messages = app.service("messages");
export const events = app.service("events");
export const auth = app.service("auth/login");
export const talks = app.service("talks");
export const adminCount = app.service("/admin/count");
export const tags = app.service("tags");
export const q = app.service("q");
export const sessions = app.service("sessions");
export const datas = app.service("datas");
export const leads = app.service("leads");
export const books = app.service("books");
export const statisticsSessions = app.service("/statistics/sessions");
export const statisticsPages = app.service("/statistics/pages");
export const notifications = app.service("/notifications/push");
export const services = app.service("/services");
export const servicesPublish = (id) => app.service(`/services/publish/${id}`);
export const generateBanner = (id) => app.service(`/generate/banners/${id}`);
export const generateCatalog = app.service("/catalog/generate");
export const payments = app.service("/payments");
export const servicesPayments = app.service("/payments/services");
export const generateChatGptForTag = app.service("/chatgpt/generate/tags");
export const savePromptsForTag = (id) =>
  app.service(`/chatgpt/prompts/tags/${id}`);

let authHook = (hook) => {
  let token = _.get(store.getState(), "session.user.token");

  if (token) {
    hook.params.headers = Object.assign(
      {},
      {
        Authorization: `Bearer ${token}`,
      },
      hook.params.headers
    );
  }
  return hook;
};

let queryAdmin = (hook) => {
  hook.params.headers = hook.params.headers || {};
  hook.params.headers["request-from-backoffice"] = true;
  hook.params.headers["Source"] = "web";
  return hook;
};

let handleAuthError = (hook) => {
  if (
    hook.error &&
    (hook.error.name === "NotAuthenticated" ||
      hook.error.message === "error.authentication")
  ) {
    localStorage.removeItem("user");
    store.dispatch(setUser(null));
    window.location = "/admin/login";
  }
  return hook;
};

app.hooks({
  before: {
    all: [authHook, queryAdmin],
  },
  error: {
    all: handleAuthError,
  },
});

export default {
  selections,
  users,
  usersAll,
  usersPublic,
  conferences,
  matchs,
  promos,
  events,
  talks,
  q,
  sessions,
  leads,
  tags,
  books,
  generateBanner,
  notifications,
  services,
  servicesPublish,
  payments,
  servicesPayments,
  generateChatGptForTag,
  savePromptsForTag,
};
