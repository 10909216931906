
import { types } from 'actions/session'

let user = null
try {
  user = JSON.parse(localStorage.getItem('user'))
} catch (err) {
  console.log('Failed to get user')
}

const initialState = {
  user: user
}

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    // case types.SET_TOKEN:
    //   const { access_token, refresh_token } = action

    //   if (access_token && refresh_token) {
    //     localStorage.setItem('session', JSON.stringify({ access_token, refresh_token }))
    //   } else {
    //     localStorage.removeItem('session')
    //   }
    //   return {
    //     ...state,
    //     access_token,
    //     refresh_token
    //   }

    case types.SET_USER:
      return {
          ...state,
          user: action.payload
      }

    default:
      break
  }
  return state
}

export default sessionReducer
