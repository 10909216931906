import React, { Component } from 'react'
import './style.css'

class Block extends Component {
  render() {
    let className = 'dashboard-block-wrapper '
    if (this.props.className) {
      className += this.props.className
    }
    return (
      <div className={className}>
        <div className='dashboard-block'>
          <div className='title'>{this.props.title}</div>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Block