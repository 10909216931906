import React, { Component } from 'react'
import './style.css'

const FormInput = ({onChange, value, type, fullWidth, placeholder}) => {
  type = type || 'text'
  let className = 'form-input'
  if (fullWidth) {
    className += ' full-width'
  }
  return (
    <input type={type}
           placeholder={placeholder}
           className={className}
           onChange={onChange}
           value={value} />
  )
}

export default FormInput