import React, { Component } from 'react'
import { getEmarketingWeeklyFeedback } from 'utils/data'

import columns from './columns.json'
import filters from './filters.json'

import PageListContainer from 'components/PageListContainer'
import View from './component'


class FeedbacksContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      model: 'talks',
      populate: {},
      columns,
      filters,
      container: {
        weeklyFeedback: null
      }
    }
    // this.fetchWeeklyFeedback.call(this)
  }

  // fetchWeeklyFeedback () {
  //   getEmarketingWeeklyFeedback().then(({data}) => {
  //     this.setState({
  //       container: {
  //         ...this.state.container,
  //         weeklyFeedback: data
  //       }
  //     })
  //   }).catch(e => {
  //     console.error(e)
  //   })
  // }

  render() {
    return (
      <PageListContainer {...this.state}
                      location={this.props.location}
                      view={View} />
    )
  }
}

export default FeedbacksContainer

