import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import './index.css'

import Layout from 'components/Layout'

const mapStateToProps = state => ({
  session: state.session
})

class Forbidden extends Component {
  render() {
    const user = _.get(this.props, 'session.user')
    return (
      <Layout {...this.props}>
        <div className='forbiddenPage'>
          <div>Vous n'êtes pas autorisé à accéder à cette page</div>

          { user &&
            <div >
              <div className='connected-as'>Connecté en tant que : </div>
              <div>{user.firstname} {user.lastname}</div>
              <div>{user.email}</div>
            </div>
          }
        </div>
      </Layout>
    )
  }
}

export default connect(mapStateToProps, null, null, { pure: true })(Forbidden)
