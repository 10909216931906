import React, { Component } from 'react'
import Pagination from 'react-paginate'
import './style.css'

class CustomPagination extends Component {
  render() {
    return (
      <Pagination previousLabel={"Précédent"}
                  nextLabel={"Suivant"}
                  containerClassName={"pagination"}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  {...this.props} />
    )
  }
}

export default CustomPagination
