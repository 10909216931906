import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'
import './style.css'

import Layout from 'components/Layout'
import Form from 'components/Form'

import Checkbox from 'material-ui/Checkbox'
import RaisedButton from 'material-ui/RaisedButton'

class FeedbackDetailView extends Component {
  render() {
    const { container, form, data, onChange, onSubmit } = this.props
    const user = _.get(this.props, 'data.author')
    return (
      <Layout {...this.props} title="Feedback Detail">
        <div className="feedbacks-detail-page detail-page">
          {user ? (
            <div className="author">
              {_.get(user, 'picture.path') ? (
                <img className="picture" src={process.env.REACT_APP_SERVER + user.picture.path} />
              ) : (
                ''
              )}
              <div>
                <NavLink to={`/admin/users/${user._id}`}>
                  {user.firstname} {user.lastname}
                </NavLink>{' '}
                - <a href={`mailto:${user.email}`}>{user.email}</a>
              </div>
            </div>
          ) : (
            ''
          )}
          {data ? (
            <div className="link">
              <a target="_blank" href={`${process.env.REACT_APP_WEB}/talks/${data.slug}`}>{`${
                process.env.REACT_APP_WEB
              }/talks/${data.slug}`}</a>
            </div>
          ) : (
            ''
          )}
          {/*<RaisedButton
            disabled={container.generateDisabled}
            label={container.generateLabel}
            primary={true}
            onClick={container.generateBanner}
            style={{ marginBottom: 20 }}
          />*/}
          <Form schema={form} data={data} onChange={onChange} onSubmit={onSubmit} />
        </div>
      </Layout>
    )
  }
}

export default FeedbackDetailView
