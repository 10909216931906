import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import Avatar from "material-ui/Avatar";
import MenuIcon from "material-ui/svg-icons/navigation/more-vert";

import { SpeedDial, BubbleList, BubbleListItem } from "react-speed-dial";
import "./style.css";

const groups = [
  {
    title: "General",
    links: [
      // {
      //   path: '/admin/promos',
      //   label: 'Partage & Reseaux',
      //   icon: 'fab fa-facebook',
      // },
      // {
      //   path: '/admin/notifications',
      //   label: 'Notifications',
      //   icon: 'fa fa-bell',
      // },
      // {
      //   path: '/admin/services',
      //   label: 'Services',
      //   icon: 'fas fa-graduation-cap',
      // },
      {
        path: "/admin/seo/tags",
        label: "SEO",
        icon: "fas fa-bullseye",
      },
      {
        path: "/admin/adsmanager",
        label: "Ads Manager",
        icon: "fas fa-bullseye",
      },
      {
        path: "/admin/leads",
        label: "Leads",
        icon: "fas fa-bullseye",
      },
      {
        path: "/admin/selections",
        label: "Selections",
        icon: "fa fa-file-pdf",
      },
      // {
      //   path: '/admin/books',
      //   label: 'Livres',
      //   icon: 'fa fa-book',
      // },
      // {
      //   path: '/admin/payments',
      //   label: 'Payments',
      //   icon: 'fa fa-euro-sign',
      // },
    ],
  },
  {
    title: "Données",
    links: [
      // {
      //   datakey: 'matchs',
      //   path: '/admin/matchs',
      //   label: 'Matchs',
      //   icon: 'fa fa-share-alt',
      // },
      {
        datakey: "users",
        path: "/admin/users",
        label: "Utilisateurs",
        icon: "fas fa-user",
      },
      {
        datakey: "pro",
        path: "/admin/pros",
        label: "Speakers Pro",
        icon: "fab fa-product-hunt",
      },
      {
        datakey: "talks",
        path: "/admin/talks",
        label: "Talks",
        icon: "far fa-comment",
      },
      // {
      //   datakey: 'events',
      //   path: '/admin/events',
      //   label: 'Evenements',
      //   icon: 'far fa-calendar-alt',
      // },
    ],
  },
];

let links = [];
groups.forEach((group) => {
  links = links.concat(group.links);
});

export const Icon = ({ className }) => {
  return <i className={className}></i>;
};

const SidebarItem = ({ path, label, icon, value }) => {
  return (
    <NavLink className="nav-el" to={path} exact={true} activeClassName="active">
      <i className={icon}></i>
      <span>{label}</span>
      {value >= 0 ? <span> ({value})</span> : ""}
    </NavLink>
  );
};

export const Sidebar = ({ onLogout, ...props }) => {
  return (
    <div className="sidebar">
      {groups.map((group, index) => {
        return (
          <div className="nav-group" key={index}>
            {group.title ? <div className="nav-title">{group.title}</div> : ""}
            {group.links.map((link, linkIndex) => {
              return (
                <SidebarItem
                  key={linkIndex}
                  value={props[link.datakey]}
                  {...link}
                />
              );
            })}
          </div>
        );
      })}

      <div className="nav-el">
        <a onClick={onLogout}>
          <i className="fas fa-sign-out-alt" aria-hidden="true"></i>
          <span>Se déconnecter</span>
        </a>
      </div>
    </div>
  );
};

export const MobileNavbar = ({ onLogout, ...props }) => {
  return (
    <SpeedDial
      className="mobile-navbar"
      positionH="left"
      positionV="bottom"
      hasBackdrop={true}
      icon={<MenuIcon />}
    >
      <BubbleList>
        {links.map((link, index) => {
          let label = link.label;
          if (props[link.datakey]) {
            label += ` (${props[link.datakey]})`;
          }
          return (
            <BubbleListItem
              key={index}
              href={link.path}
              primaryText={label}
              rightAvatar={
                <Avatar
                  backgroundColor="#413e50"
                  icon={<Icon className={link.icon} />}
                />
              }
            />
          );
        })}
      </BubbleList>
    </SpeedDial>
  );
};

MobileNavbar.displayName = "ExampleBasic";
