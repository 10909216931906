import axios from 'axios'

/* EXAMPLES
 find (params) :
   {
    query: { $limit: 50, $sort: '-createdAt'}
   }

*/

const DOMAIN = process.env.REACT_APP_SERVER
const VERSION = process.env.REACT_APP_SERVER_VERSION

const API = {
  service: (endpoint) => ({
    create: async (body) => {
      console.log('CREATE : ', endpoint, body)
    },
    find: async (params) => {
      console.log('FIND : ', endpoint, params)
    }
  })
}

export default API