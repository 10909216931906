import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Layout from 'components/Layout'
import Block from 'components/Block'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import Input from 'components/FormInput'
import _ from 'lodash'
import services from 'services'
import moment from 'moment'
import './style.css'
import RaisedButton from 'material-ui/RaisedButton'
import * as snackbarActions from 'actions/snackbar'

const recipientOptions = [{ value: 'speaker', label: 'Speakers' }, { value: 'organizer', label: 'Organisateurs' }]
const typeOptions = [{ value: 'new-speaker', label: 'Nouveau speaker 👋' }]

const NotificationPreview = ({ title, description, date }) => (
  <div className="notification-preview">
    <div className="notification-header">
      <div>LESPEAKERS</div>
      <div>{moment(date).fromNow()}</div>
    </div>
    {!!(title && description) ? (
      <Fragment>
        <div className="notification-title">{title}</div>
        <div className="notification-description">{description}</div>
      </Fragment>
    ) : (
      <div className="">Veuillez remplir tous les champs obligatoires pour voir votre notification</div>
    )}
  </div>
)

const mapDispatchToProps = dispatch => ({
  snackbarActions: bindActionCreators(snackbarActions, dispatch)
})

class Notifications extends PureComponent {
  state = {
    recipient: null,
    type: typeOptions[0],
    talk: null,
    completeTalk: null,
    title: '',
    description: '',
    notifications: []
  }
  componentDidMount() {
    this.fetchNotifications()
  }
  fetchNotifications = async () => {
    try {
      const notifications = await services.notifications.find()
      this.setState({ notifications })
    } catch (err) {
      console.error(err)
    }
  }
  onSearchRecipient = async q => {
    try {
      const users = await services.users.find({
        query: {
          q,
          $limit: 10,
          $skip: 0
        }
      })
      return users.data.map(user => ({
        value: user._id,
        label: `${user.firstname} ${user.lastname}`,
        user
      }))
    } catch (err) {
      console.error(err)
    }
  }
  onChangeRecipient = recipient => {
    this.setState({ recipient: recipient })
  }
  onSearchTalk = async q => {
    try {
      const talks = await services.search.find({
        query: {
          q,
          $limit: 10,
          $skip: 0
        }
      })
      return talks.data.map(talk => ({
        value: talk._id,
        label: `${talk.author.firstname} ${talk.author.lastname} | ${talk.title}`,
        data: talk
      }))
    } catch (err) {
      console.error(err)
    }
  }
  onChangeTalk = talk => {
    this.setState({
      talk,
      title: `${talk.data.author.firstname} ${talk.data.author.lastname} | Nouveau speaker 👋`,
      description: talk.data.title
    })
  }
  onChangeTitle = (e, value) => {
    value = _.get(e, 'target.value') || value || ''
    this.setState({ title: value })
  }
  onChangeDescription = (e, value) => {
    value = _.get(e, 'target.value') || value || ''
    this.setState({ description: value })
  }

  isValid = () => {
    const recipientIsGroupOrUserWithDevices =
      _.get(this.state.recipient, 'value') === 'speaker' ||
      _.get(this.state.recipient, 'value') === 'organizer' ||
      _.get(this.state.recipient, 'user.devices.length') > 0
    return (
      !!this.state.recipient &&
      !!recipientIsGroupOrUserWithDevices &&
      !!this.state.title &&
      !!this.state.description &&
      !!this.state.talk &&
      !!this.state.type
    )
  }

  onSubmit = async () => {
    if (window.confirm("Vous êtes sur le point d'envoyer une push notification à un ou plusieurs utilisateurs")) {
      console.log('LETS GO')
      const { recipient, talk, type, title, description } = this.state
      try {
        const result = await services.notifications.create({
          recipient: recipient.value,
          talk: talk.value,
          type: type.value,
          title,
          description
        })
        this.props.snackbarActions.open('success', 'Notification envoyé')
      } catch (err) {
        console.error(err)
        this.props.snackbarActions.open(
          'error',
          'Oups, une erreur est survenue, ATTENTION, il est possible que certain user est reçu la notification'
        )
      }
    } else {
      console.log('cancel')
    }
  }

  render() {
    const { notifications, recipient, type, talk, title, description } = this.state

    const nbDevices = _.get(recipient, 'user.devices.length')
    let nbIOS = 0
    let nbAndroid = 0
    if (nbDevices > 0) {
      for (const device of recipient.user.devices) {
        if (device.OS === 'ios') {
          nbIOS++
        } else {
          nbAndroid++
        }
      }
    }
    return (
      <Layout {...this.props} title="Notification">
        <div className="notification-page md-layout">
          <Block className="md-flex-100" title="Formulaire">
            <form className="form">
              <div className={'form-group' + (!recipient ? ' error' : '')}>
                <label>Destinataire(s) de la notification</label>
                <AsyncSelect
                  isClearable={true}
                  classNamePrefix="form-select"
                  className="form-select"
                  defaultOptions={recipientOptions}
                  placeholder="Vous pouvez rechercher un utilisateur ou choisir un groupe d'utilisateur (organisateur, speaker)"
                  value={recipient}
                  onChange={this.onChangeRecipient}
                  loadOptions={this.onSearchRecipient}
                />
                {nbDevices > 0 && (
                  <div className="info">
                    {nbDevices} mobile device trouvé. ({nbIOS} IOS | {nbAndroid} Android)
                  </div>
                )}
                {recipient && recipient.user && !nbDevices && (
                  <div className="error-message">Aucun téléphone trouvé pour cet utilisateur</div>
                )}
              </div>
              <div className="form-group">
                <label>Type de notification</label>
                <Select
                  placeholder="Type de notification"
                  value={type}
                  onChange={this.onChangeRecipient}
                  options={typeOptions}
                />
              </div>
              <div className="border"></div>
              {type && type.value === 'new-speaker' && (
                <div className={'form-group' + (!talk ? ' error' : '')}>
                  <label>Selection du talk</label>
                  <AsyncSelect
                    placeholder="Rechercher un talk par titre, tag ou nom d'utilisateur"
                    classNamePrefix="form-select"
                    className="form-select"
                    isClearable={true}
                    value={talk}
                    onChange={this.onChangeTalk}
                    loadOptions={this.onSearchTalk}
                  />
                </div>
              )}
              {type && type.value === 'new-speaker' && !!talk && (
                <Fragment>
                  <div className={'form-group' + (!title ? ' error' : '')}>
                    <label>Titre de notification</label>
                    <Input value={title} onChange={this.onChangeTitle}></Input>
                  </div>
                  <div className={'form-group' + (!description ? ' error' : '')}>
                    <label>Description de notification</label>
                    <Input value={description} onChange={this.onChangeDescription}></Input>
                  </div>
                </Fragment>
              )}
            </form>
          </Block>
          <Block className="md-flex-100" title="Aperçu de notification">
            <NotificationPreview title={title} description={description} />
          </Block>
          <Block className="md-flex-100" title="Envoi de notification">
            <div className="form-actions">
              {!this.isValid() && <div className="error-message">Veuillez remplir tous les champs obligatoires</div>}
              <RaisedButton
                disabled={!this.isValid()}
                label={'Envoyer cette notification'}
                primary={true}
                onClick={this.onSubmit}
              />
            </div>
          </Block>
          <Block className="notifications md-flex-100" title="Liste des précédentes notifications">
            {notifications.map(n => (
              <div className="notification" key={n._id}>
                <NotificationPreview title={n.title} description={n.body} date={n.createdAt} />
                <div className="notification-data">
                  <div>
                    <strong>Expéditeur : </strong> {n.sender.firstname} {n.sender.lastname}
                  </div>
                  <div>
                    {' '}
                    <strong>Destinaire : </strong>{' '}
                    {n.groupRecipient
                      ? n.groupRecipient
                      : n.recipient
                      ? `${n.recipient.firstname} ${n.recipient.lastname} (${n.recipient.email})`
                      : ''}
                  </div>
                  <div>
                    <strong>Success :</strong>
                    <div className="device-list">
                      {n.result.successDevices.map(d => (
                        <div className="device-item" key={d._id}>
                          - {d.OS.toUpperCase()} :{' '}
                          <strong>
                            {d.user && d.user.firstname} {d.user && d.user.lastname}
                          </strong>{' '}
                          | {d.user && d.user.email} ({d.device.substr(0, 15)}...)
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <strong>Erreur :</strong>
                    <div className="device-list">
                      {n.result.failedDevices.map(d => (
                        <div className="device-item" key={d._id}>
                          - {d.OS.toUpperCase()} :{' '}
                          <strong>
                            {d.user && d.user.firstname} {d.user && d.user.lastname}
                          </strong>{' '}
                          | {d.user && d.user.email} ({d.device.substr(0, 15)}...)
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Block>
        </div>
      </Layout>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps,
  null,
  { pure: true, withRef: true }
)(Notifications)
