import React, { Component } from 'react'
import View from './component'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import messages from 'messages.json'
import { messages as messageService } from 'services'

import { matchs } from 'services'

import * as snackbarActions from 'actions/snackbar'

const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(snackbarActions, dispatch),
})

class MatchsDetailContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      match: null,
      text: '',
    }

    this.fetch = this.fetch.bind(this)
    this.fetch()
  }

  cancel = async () => {
    const id = this.props.match.params.id
    try {
      const result = await matchs.patch(id, {
        status: 'canceled',
      })
      const match = Object.assign({}, this.state.match, { status: result.status })
      this.setState({ match })
      this.props.snackbarActions.open('success', 'Modifications sauvegardés')
    } catch (err) {
      this.props.snackbarActions.open('error', `Error : ${_.get(messages, err.message)}`)
      console.error(err)
    }
  }

  confirm = async () => {
    const id = this.props.match.params.id
    try {
      const result = await matchs.patch(id, {
        status: 'contact',
      })
      const match = Object.assign({}, this.state.match, { status: result.status })
      this.setState({ match })
      this.props.snackbarActions.open('success', 'Modifications sauvegardés')
    } catch (err) {
      this.props.snackbarActions.open('error', `Error : ${_.get(messages, err.message)}`)
      console.error(err)
    }
  }

  sendMessage = async () => {
    try {
      const id = this.props.match.params.id
      await messageService.create({
        match: id,
        content: this.state.text,
      })
      this.props.snackbarActions.open('success', 'Message envoyé')
      this.chat && this.chat.fetch()
    } catch (err) {
      console.error(err)
      this.props.snackbarActions.open('error', `Error : ${_.get(messages, err.message)}`)
    }
  }

  fetch() {
    const id = this.props.match.params.id
    matchs
      .get(id, {
        query: {
          $populate: [
            {
              path: 'event._id',
              select: 'city.name infos price typeOf nbPublic duration date name publicType',
            },
            {
              path: 'organizer',
              select: 'firstname lastname picture linkedin.publicUrl email',
            },
            {
              path: 'speaker',
              select: 'firstname lastname picture linkedin.publicUrl linkedin.headline email',
            },
            {
              path: 'conference',
              select: 'title',
            },
            {
              path: 'payroll',
              select: 'originalName path',
            },
            {
              path: 'bill',
              select: 'originalName path',
            },
            {
              path: 'purchaseOrder',
              select: 'originalName path',
            },
          ],
        },
      })
      .then((result) => {
        this.setState({ match: result })
      })
      .catch((e) => {
        console.error(e)
      })
  }

  onChangeText = (e) => {
    this.setState({ text: e.target.value })
  }

  setChatRef = (ref) => {
    this.chat = ref
  }

  render() {
    return (
      <View
        {...this.state}
        setChatRef={this.setChatRef}
        onChangeText={this.onChangeText}
        sendMessage={this.sendMessage}
        cancel={this.cancel}
        confirm={this.confirm}
      />
    )
  }
}

export default connect(null, mapDispatchToProps, null, { pure: true })(MatchsDetailContainer)
