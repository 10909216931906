import React, { Component } from 'react'

import Layout from 'components/Layout'
import Table from 'components/Table'
import Pagination from 'components/Pagination'

class Leads extends Component {
  render() {
    const { columns, data, total, currentPage, onChangePage, filters, onChangeFilters, location } = this.props
    return (
      <Layout {...this.props} title="Leads">
        <div className="lead-page">
          <div className="block">
            <Pagination forcePage={currentPage} pageCount={total / 30} onPageChange={onChangePage} />
            <Table columns={columns} data={data} location={location} />
            <Pagination forcePage={currentPage} pageCount={total / 30} onPageChange={onChangePage} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default Leads
