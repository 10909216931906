import React, { Component } from 'react'
import form from './form.json'
import { generateCatalog } from 'services'

import PageDetailContainer from 'components/PageDetailContainer'
import View from './component'
import download from 'downloadjs'

class SelectionsDetailContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      model: 'selections',
      populate: {
        path: 'users',
        select: 'email firstname lastname picture phoneNumber',
      },
      container: {
        loadingDownload: false,
        onDownloadPdf: this.onDownloadPdf,
      },
      form,
    }
  }

  /* fetch result need to be modified specificly for users array */
  afterFetch(result) {
    result.users = (result.users || []).map((user, index) => {
      return { id: user._id, text: `${user.firstname} ${user.lastname}` }
    })
    result.showPrice = true
  }

  beforeSubmit(data) {
    data.users = data.users.map((tag) => {
      return tag.id
    })
  }

  onDownloadPdf = async () => {
    if (this.state.container.loadingDownload) {
      return
    }
    this.setState({
      container: {
        ...this.state.container,
        loadingDownload: true,
      },
    })
    try {
      const data = this.pageDetailContainer.wrappedInstance.state.data
      const ids = data.users.map((u) => u.id).join(',')
      const result = await generateCatalog.create({
        ids,
        brief: data.brief,
        showPrice: data.showPrice === true,
      })
      const url = `${process.env.REACT_APP_SERVER}${result.path}`
      download(url)
      this.setState({
        container: {
          ...this.state.container,
          loadingDownload: false,
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  render() {
    return (
      <PageDetailContainer
        {...this.state}
        ref={(ref) => {
          this.pageDetailContainer = ref
        }}
        afterFetch={this.afterFetch}
        beforeSubmit={this.beforeSubmit}
        location={this.props.location}
        match={this.props.match}
        view={View}
      />
    )
  }
}

export default SelectionsDetailContainer
