import React, { Component } from 'react'

import columns from './columns.json'
// import filters from './filters.json'

import PageListContainer from 'components/PageListContainer'
import View from './component'


class EventsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      model: 'events',
      populate: {
        path: 'author', select: 'firstname lastname'
      },
      columns,
      filters: {}
    }
  }

  render() {
    return (
      <PageListContainer {...this.state}
                      location={this.props.location}
                      view={View} />
    )
  }
}

export default EventsContainer

