import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'

import RaisedButton from 'material-ui/RaisedButton'
import Layout from 'components/Layout'
import Form from 'components/Form'
import ConnectAs from 'components/ConnectAs'

class EventDetailView extends Component {
  render() {
    const { container, form, data, onChange, onSubmit } = this.props
    return (
      <Layout {...this.props} title='Lead'>
        <div className='leads-detail-page detail-page'>
          {_.get(data, 'user.picture.path') ?
            <img className='picture' src={process.env.REACT_APP_SERVER + data.user.picture.path} />
          : '' }
          <Form schema={form} data={data} onChange={onChange} onSubmit={onSubmit}/>

        </div>
      </Layout>
    )
  }
}

export default EventDetailView