import React, { Component } from 'react'
import form from './form.json'

import PageDetailContainer from 'components/PageDetailContainer'
import View from './component'

class LeadsDetailContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      model: 'leads',
      populate: {
        path: 'user',
        select: 'email firstname lastname picture phoneNumber'
      },
      form
    }
  }

  render() {
    return <PageDetailContainer {...this.state} location={this.props.location} match={this.props.match} view={View} />
  }
}

export default LeadsDetailContainer
