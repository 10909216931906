import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  fetch,
  onChangePage,
  onChangeFilters,
  buildQueryWithFilter,
} from "./utils";

const initialState = (props) => {
  return {
    data: [],
    total: 0,
    q: "",
    filter: _.get(props, "filters.filter[0].value") || 0,
    sort: props.sort || "-createdAt",
    currentPage: 0,
  };
};

class PageListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = initialState(props);

    /* generic functions, working with all kind of model (conferences, users, matchs, events ...) */
    this.fetch = fetch.bind(this, props.model, props.populate);
    this.onChangePage = onChangePage.bind(this);
    this.onChangeFilters = onChangeFilters.bind(this);
    this.buildQueryWithFilter = buildQueryWithFilter.bind(this);

    this.fetch(1);
  }

  render() {
    const View = this.props.view;
    const { columns, filters, container } = this.props;
    return (
      <View
        {...this.state}
        onChangePage={this.onChangePage}
        onChangeFilters={this.onChangeFilters}
        columns={columns}
        filters={filters}
        container={container}
        location={this.props.location}
      />
    );
  }
}

PageListContainer.propTypes = {
  model: PropTypes.string.isRequired,
  populate: PropTypes.any /* object or array */,
  view: PropTypes.func,
};

export default PageListContainer;
