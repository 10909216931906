import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'

import RaisedButton from 'material-ui/RaisedButton'
import Layout from 'components/Layout'
import Form from 'components/Form'
import ConnectAs from 'components/ConnectAs'

class EventDetailView extends Component {
  render() {
    const { container, form, data, onChange, onSubmit } = this.props
    return (
      <Layout {...this.props} title='Evenement'>
        <div className='events-detail-page detail-page'>
          { data && data.author &&
            <ConnectAs user={data.author} />
          }
          { data && !data.author &&
            <NavLink style={{ marginBottom: 20, display: 'block' }}className='create-user' to='/admin/users/create'><RaisedButton className='raised' secondary={true}>Creer un utilisateur</RaisedButton></NavLink>
          }
          {_.get(data, 'author.picture.path') ?
            <img className='picture' src={process.env.REACT_APP_SERVER + data.author.picture.path} />
          : '' }
          <Form schema={form} data={data} onChange={onChange} onSubmit={onSubmit}/>

          { data && data.author &&
            <ConnectAs user={data.author} />
          }
        </div>
      </Layout>
    )
  }
}

export default EventDetailView