import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import services from 'services'

import form from './form.json'
import messages from 'messages.json'

import { getEmarketingWeeklyFeedback, setEmarketingWeeklyFeedback } from 'utils/data'
import PageDetailContainer from 'components/PageDetailContainer'
import View from './component'

import * as snackbarActions from 'actions/snackbar'

const mapDispatchToProps = (dispatch) => ({
  snackbarActions: bindActionCreators(snackbarActions, dispatch),
})

class FeedbackDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      model: 'conferences',
      populate: { path: 'author', select: 'firstname lastname email picture' },
      form,
      container: {
        // emarketingWeeklyFeedbackId: null,
        // emarketingWeeklyFeedback: false,
        // generateBanner: this.generateBanner,
        // generateDisabled: false,
        // generateLabel: 'Générer bannière',
      },
    }

    this.afterFetch = this.afterFetch.bind(this)
    // this.beforeChange = this.beforeChange.bind(this)
    this.beforeSubmit = this.beforeSubmit.bind(this)
    // this.fetchData.call(this)
    // this.updateEmarketingWeeklyFeedback = this.updateEmarketingWeeklyFeedback.bind(this)
  }

  /* fetch result need to be modified specificly for feedbacks (tags) */
  afterFetch(result) {
    result.tags = (result.tags || []).map((tag, index) => {
      return { id: index, text: tag }
    })
  }

  /* state is the PageDetailContainer state (with fetched data) */
  // beforeChange(key, value) {
  //   if (key === 'emarketingWeeklyFeedback') {
  //     value = !this.state.container['emarketingWeeklyFeedback']
  //     if (value === true) {
  //       return this.updateEmarketingWeeklyFeedback()
  //     } else {
  //       this.props.snackbarActions.open(
  //         'error',
  //         'Veuillez garder toujours un feedback de la semaine activé, activez un autre feedback pour remplacer celui la'
  //       )
  //     }
  //   }
  // }

  beforeSubmit(data) {
    console.log('beforeSubmit : ', data)
    data.translated_title = data.translated_title || {}
    data.translated_description = data.translated_description || {}
    data.translated_title.fr = data.title
    data.translated_description.fr = data.description
    data.tags = data.tags.map((tag) => {
      return tag.text
    })
  }

  // fetchData() {
  //   getEmarketingWeeklyFeedback()
  //     .then(({ id, data }) => {
  //       let emarketingWeeklyFeedback = false
  //       if (this.props.match.params.id === data) {
  //         emarketingWeeklyFeedback = true
  //       }
  //       this.setState({
  //         container: {
  //           ...this.state.container,
  //           emarketingWeeklyFeedbackId: id,
  //           emarketingWeeklyFeedback,
  //         },
  //       })
  //     })
  //     .catch((e) => {
  //       console.error(e)
  //     })
  // }

  // generateBanner = async () => {
  //   console.log('GENERATE')
  //   const id = this.props.match.params.id
  //   this.setState({
  //     container: {
  //       ...this.state.container,
  //       generateDisabled: true,
  //       generateLabel: 'En cours',
  //     },
  //   })
  //   try {
  //     const result = await services.generateBanner(id).create({})
  //     this.props.snackbarActions.open('success', 'Bannière généré')
  //     this.setState({
  //       container: {
  //         ...this.state.container,
  //         generateDisabled: false,
  //         generateLabel: 'Générer bannière',
  //       },
  //     })
  //   } catch (err) {
  //     console.error(err)
  //     this.props.snackbarActions.open('error', 'Erreur de génération de bannière')
  //     this.setState({
  //       container: {
  //         ...this.state.container,
  //         generateDisabled: false,
  //         generateLabel: 'Générer bannière',
  //       },
  //     })
  //   }
  // }

  // updateEmarketingWeeklyFeedback() {
  //   setEmarketingWeeklyFeedback(this.state.container.emarketingWeeklyFeedbackId, {
  //     typeOf: '/emarketing/feedbacks/week',
  //     data: this.props.match.params.id,
  //   })
  //     .then((result) => {
  //       this.setState({
  //         data: {
  //           ...this.state.data,
  //           emarketingWeeklyFeedback: true,
  //         },
  //       })
  //       this.props.snackbarActions.open('success', 'Feedback de la semaine mis à jour')
  //     })
  //     .catch((e) => {
  //       console.error(e)
  //       this.setState({
  //         data: {
  //           ...this.state.data,
  //           emarketingWeeklyFeedback: false,
  //         },
  //       })
  //       this.props.snackbarActions.open('error', `Error : ${_.get(messages, e.message)}`)
  //     })
  // }

  render() {
    return (
      <PageDetailContainer
        {...this.state}
        afterFetch={this.afterFetch}
        // beforeChange={this.beforeChange}
        beforeSubmit={this.beforeSubmit}
        location={this.props.location}
        match={this.props.match}
        view={View}
      />
    )
    // return <View {...this.state} onChange={this.onChange} onSubmit={this.onSubmit} />
  }
}

export default connect(null, mapDispatchToProps, null, { pure: true })(FeedbackDetail)
