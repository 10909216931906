import React, { Component } from "react";

import columns from "./columns.json";
import filters from "../Users/filters.json";

import PageListContainer from "components/PageListContainer";
import View from "../SEOCommon/list";

class SEOContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: "users",
      populate: [],
      columns,
      filters,
      container: {
        model: "users",
      },
    };
  }

  render() {
    return (
      <PageListContainer
        {...this.state}
        location={this.props.location}
        view={View}
      />
    );
  }
}

export default SEOContainer;
