import _ from 'lodash'

var tid = 0
var ids = []

export const onKeypressDelay = (ms, callback) => {
  clearTimeout(tid)
  tid = setTimeout(callback, ms)
}

/* ondelay, clear if call multiple times with the same id */
export const onDelay = (ms, id, callback) => {
  if (ids.indexOf(id) === -1) {
    /* if this timeout doesnt exist already */
    ids.push(id)
  } else {
    /* else, clear and restart a new one */
    clearTimeout(tid)
  }
  tid = setTimeout(() => {
    var index = ids.indexOf(id)
    if (index !== -1) {
      ids.splice(index, 1)
      callback()
    }
  }, ms)
}

export const handleJsonLink = (item, link) => {
  if (!link) return link
  let regex = /(.*?)\$\{(.*?)\}/
  let result = regex.exec(link)
  if (result.length !== 3) {
    return link
  }
  return `${result[1]}${_.get(item, result[2])}`
}