import React, { Component } from 'react'

import columns from './columns.json'
// import filters from './filters.json'

import PageListContainer from 'components/PageListContainer'
import View from './component'

class LeadsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      model: 'selections',
      populate: [
        {
          path: 'users',
          select: 'firstname lastname email picture',
        },
      ],
      columns,
      filters: {},
    }
  }

  render() {
    return <PageListContainer {...this.state} location={this.props.location} view={View} />
  }
}

export default LeadsContainer
